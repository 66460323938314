import React, { createContext, useContext } from "react";
import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";

export const FirebaseContext = createContext<any>(null);

export const FirebaseProvider: React.FC = ({ children }) => {
    const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!);
    const app = initializeApp(config);

    if (process.env.REACT_APP_ENVIRONMENT === "development") {
        connectAuthEmulator(getAuth(), "http://localhost:9099");
        connectFirestoreEmulator(getFirestore(), "localhost", 8080);
        connectFunctionsEmulator(getFunctions(app), "localhost", 5001);
        connectStorageEmulator(getStorage(), "localhost", 9199);
    }

    return (
        <FirebaseContext.Provider value={app}>
            {children}
        </FirebaseContext.Provider>
    );
};

// from context provided firebase
const useFirebase = (): FirebaseApp => {
    return useContext(FirebaseContext);
};

export { useFirebase };
