import { doc, getFirestore, updateDoc } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "../../../../../../components/grid";
import styled from "styled-components";
import { OutlineButton } from "../../../../../../components/atoms/Button";
import Breadcrumb from "../../../../../../components/Breadcrumb";
import Checklist from "../../../../../../components/Checklist";
import { Content } from "../../../../../../components/Content";
import ListItemLabel from "../../../../../../components/ListItemLabel";
import Loader from "../../../../../../components/Loader";
import { Collections } from "../../../../../../constants/collections";
import { NEUTRAL_200 } from "../../../../../../constants/colours";
import { useStandard } from "../../../../../../hooks/useCourses";
import { useEnrolment } from "../../../../../../hooks/useEnrolments";
import { Task } from "../../../../../../types/Course";
import { useAuth } from "../../../../../../hooks/useAuth";
import { UserRole } from "../../../../../../types/User";

const HeadingWrapper = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${NEUTRAL_200};
  margin-bottom: 16px;
  h3 {
    margin-bottom: 8px;
  }
`;

export default function EnrolmentDetail() {
  const [user] = useAuth();
  const { id, standardId, enrolmentId } =
    useParams<{ id: string; standardId: string; enrolmentId?: string }>();
  const [course, , standard, loadingStandard] = useStandard(
    id!,
    Number(standardId)
  );
  const [enrolment, loadingEnrolment] = useEnrolment(enrolmentId!);
  const navigate = useNavigate();
  if ((!standard && loadingStandard) || loadingEnrolment) {
    return <Loader />;
  }

  const allCompleted = standard?.tasks.every(
    (t) => (enrolment?.tasks[t.id] || {}).completed
  );
  const onTaskClick = (task: Task) => {
    navigate(`/enrolments/${enrolmentId}/${course?.id}/${task.id}`);
  };

  const onCourseClick = () => {
    navigate(-1);
  };

  const onCompleted = (completed: boolean) => {
    if (enrolment && standard) {
      standard.tasks.forEach((task) => {
        enrolment.tasks[task.id] = {
          ...enrolment.tasks[task.id],
          completed,
        };
      });

      updateDoc(doc(getFirestore(), Collections.enrolments, enrolment.id), {
        tasks: enrolment.tasks,
      });
    }
  };

  const onMarkAllCompleted = () => {
    onCompleted(true);
  };

  const onMarkAllUncompleted = () => {
    onCompleted(false);
  };

  return (
    <Content>
      <Row>
        <Col xs>
          <Breadcrumb text={'Standards'} onClick={onCourseClick} />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <HeadingWrapper>
            <h3>{standard?.name}</h3>
            <ListItemLabel>{standard?.number}</ListItemLabel>
          </HeadingWrapper>
        </Col>
      </Row>
      <Row>
        <Col xs>
          {standard?.tasks.map((task) => {
            const selected =
              (enrolment?.tasks[task.id] || {}).completed || false;
            const highlighted =
              (enrolment?.tasks[task.id] || {}).readyForReview || false;
            return (
              <div onClick={() => onTaskClick(task)} key={task.id}>
                <Checklist
                  text={task.name}
                  readOnly
                  selected={selected}
                  highlighted={highlighted}
                  taskNumber={task.number}
                />
              </div>
            );
          })}
        </Col>
      </Row>
      {user?.role !== UserRole.student && (
        <Row>
          <Col xs>
            {allCompleted ? (
              <OutlineButton onClick={onMarkAllUncompleted}>
                Mark all as incomplete
              </OutlineButton>
            ) : (
              <OutlineButton onClick={onMarkAllCompleted}>
                Mark all as completed
              </OutlineButton>
            )}
          </Col>
        </Row>
      )}
    </Content>
  );
}
