import { Enrolment, EnrolmentTask} from "../types/Course";


export default function getCourseCompletionStatus(enrolment: Enrolment) {
    let completedCount = 0;


    Object.values(enrolment.tasks).forEach((task: EnrolmentTask) => {
        if (task.completed) {
            completedCount += 1;
        }
    });

    return completedCount;
}
