import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "../../../components/grid";
import { IconSource } from "../../../components/atoms/Icon";
import { Content } from "../../../components/Content";
import CourseToggleSelect from "../../../components/CourseToggleSelect";
import { HeadingD } from "../../../components/Heading";
import ListItemLabel from "../../../components/ListItemLabel";
import Loader from "../../../components/Loader";
import { useBCITOCourses } from "../../../hooks/useCourses";

export default function AllCourses() {
    const [bcitoCourses, loadingBCITOCourses] = useBCITOCourses();

    const navigate = useNavigate();

    if (loadingBCITOCourses) {
        return <Loader />;
    }

    const onCourseClick = (id: string) => {
        navigate(`/courses/${id}`);
    };

    return (
        <Content>
            <Row>
                <Col xs>
                    <HeadingD>Courses</HeadingD>
                    {!!bcitoCourses && (
                        <>
                            <ListItemLabel>BCITO Apprenticeships</ListItemLabel>
                            {bcitoCourses?.map((course) => (
                                <CourseToggleSelect
                                    key={course.id}
                                    course={course.name}
                                    selected={false}
                                    icon={IconSource.next}
                                    onClick={() => onCourseClick(course.id)}
                                />
                            ))}
                        </>
                    )}
                </Col>
            </Row>
        </Content>
    );
}
