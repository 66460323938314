import styled from 'styled-components';
import React from 'react';
import { NEUTRAL_200, NEUTRAL_700, NEUTRAL_900 } from '../constants/colours';
import { LABEL } from '../constants/typography';

interface BarProps {
  filled?: boolean;
}

const Label = styled.div`
  ${LABEL};
  color: ${NEUTRAL_900};
`;

const Container = styled.div`
  margin-bottom: 32px;
`;

const Circle = styled.div<BarProps>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${(props) => (props.filled ? NEUTRAL_900 : NEUTRAL_200)};
  z-index: 100;
`;

const Bar = styled.div<BarProps>`
  flex-grow: 1;
  background-color: ${(props) => (props.filled ? NEUTRAL_900 : NEUTRAL_200)};
  height: 12px;

  :first-of-type {
    margin-left: 8px;
  }

  :last-of-type {
    margin-right: 8px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 4px 0;
  position: relative;
`;

const BarWrapper = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 0;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${NEUTRAL_700};
`;

interface Props {
  label: string;
  value: number;
  name?: string;
  setFieldValue?: any;
  bottomDescription?: string;
  middleDescription?: string;
  topDescription?: string;
  disabled?: boolean;
}

export default function SelfAssessmentBar(props: Props) {
  const onPress = (value: number) => () => {
    if (!props.disabled) {
      props.setFieldValue && props.setFieldValue(props.name, value);
    }
  };

  return (
    <Container>
      <Label>{props.label}</Label>
      <Wrapper>
        <Circle filled={props.value >= 1} onClick={onPress(1)} />
        <Circle filled={props.value >= 2} onClick={onPress(2)} />
        <Circle filled={props.value >= 3} onClick={onPress(3)} />
        <Circle filled={props.value >= 4} onClick={onPress(4)} />
        <Circle filled={props.value >= 5} onClick={onPress(5)} />
        <BarWrapper>
          <Bar filled={props.value >= 2} />
          <Bar filled={props.value >= 3} />
          <Bar filled={props.value >= 4} />
          <Bar filled={props.value >= 5} />
        </BarWrapper>
      </Wrapper>

      <DescriptionWrapper>
        <span>{props.bottomDescription || 'Negative'}</span>
        <span>{props.middleDescription || 'Okay'}</span>
        <span>{props.topDescription || 'Positive'}</span>
      </DescriptionWrapper>
    </Container>
  );
}
