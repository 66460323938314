export const ORANGE_500 = '#FF8610';
export const RED_100 = '#FF3D3D';
export const RED_500 = '#F0382F';

export const NEUTRAL_100 = '#FFFFFF';
export const NEUTRAL_200 = '#F4F4F5';
export const NEUTRAL_300 = '#EBEBEB';
export const NEUTRAL_400 = '#CFCFCF';
export const NEUTRAL_500 = '#B4B4B4';
export const NEUTRAL_600 = '#999999';
export const NEUTRAL_700 = '#707070';
export const NEUTRAL_800 = '#474747';
export const NEUTRAL_900 = '#1F1F1F';

export const PRIMARY = NEUTRAL_300;
