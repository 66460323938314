import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../../components/grid';
import styled from 'styled-components';
import * as yup from 'yup';
import Action from '../../../../components/Action';
import { AssessmentCard } from '../../../../components/AssessmentCard';
import { AssessmentNote } from '../../../../components/AssessmentNote';
import {
  OutlineButton,
  PrimaryButton,
} from '../../../../components/atoms/Button';
import { Divider } from '../../../../components/atoms/Divider';
import { IconSource } from '../../../../components/atoms/Icon';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Content } from '../../../../components/Content';
import { Dialog } from '../../../../components/Dialog';
import { Form, Text } from '../../../../components/Forms';
import Loader from '../../../../components/Loader';
import SelfAssessmentFields, {
  SelfAssessmentSchema,
  SelfAssessmentValues,
} from '../../../../components/SelfAssessmentFields';
import { Collections } from '../../../../constants/collections';
import { useBCITOCourses } from '../../../../hooks/useCourses';
import { useEnrolments } from '../../../../hooks/useEnrolments';
import { useSelfAssessment } from '../../../../hooks/useSelfAssessment';
import { UserProfile } from '../../../../types/UserProfile';
import Courses from "../../../../components/Courses";

const HeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MarginButton = styled(PrimaryButton)`
  margin-bottom: 8px;
  margin-top: 32px;
`;

interface StudentOverviewProps {
  profile: UserProfile;
}

export default function StudentOverview({ profile }: StudentOverviewProps) {
  const metricsFormRef = useRef<FormikProps<any>>(null);
  const navigate = useNavigate();
  const db = getFirestore();
  const [todayAssessment, loadingAssessment] = useSelfAssessment(
    profile.id,
    new Date()
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [enrolments, loadingEnrolments] = useEnrolments(profile.id);
  const [bcitoCourses, loadingBcitoCourses] = useBCITOCourses();

  const onAbsentPress = () => {
    metricsFormRef.current?.setFieldValue('absent', true);
    setDialogOpen(true);
  };

  const handleMetricSubmit = (values: SelfAssessmentValues) => {
    if (
      values.tahaHinengaro ||
      values.tahaTinana ||
      values.tahaWairua ||
      values.tahaWhanau
    ) {
      setDialogOpen(true);
    }
  };

  const handleNoteSubmit = async ({ note }: { note: string }) => {
    const metrics = { ...metricsFormRef?.current?.values };
    Object.keys(metrics).forEach(
      (key) => metrics[key] === undefined && delete metrics[key]
    );

    try {
      await addDoc(collection(db, Collections.assessments), {
        pid: profile.id,
        date: serverTimestamp(),
        ...metrics,
        note,
      });
    } catch (e) {
      console.log('error', e);
    } finally {
      setDialogOpen(false);
    }
  };

  const onHistoryPress = () => {
    navigate(`/profiles/students/${profile.id}/history`);
  };

  const renderAssessmentControls = () => {
    if (loadingAssessment) {
      return <Loader small />;
    }

    if (!todayAssessment) {
      return (
        <ButtonGroup>
          <OutlineButton onClick={onAbsentPress}>Mark absent</OutlineButton>
          <PrimaryButton type={'submit'} disabled={false}>
            Submit
          </PrimaryButton>
        </ButtonGroup>
      );
    }

    if (todayAssessment.note) {
      return (
        <AssessmentNote>
          <p>{todayAssessment?.note}</p>
        </AssessmentNote>
      );
    }
  };

  if (loadingEnrolments || loadingAssessment || loadingBcitoCourses) {
    return <Loader />;
  }

  return (
    <Content>
      <HeaderRow>
        <Col xs>
          <h3>Daily report</h3>
        </Col>
        <Col>
          <Action
            text={'History'}
            icon={IconSource.next}
            right
            onClick={onHistoryPress}
          />
        </Col>
      </HeaderRow>
      <AssessmentCard>
        <Row>
          <Col xs>
            <Form
              initialValues={{
                tahaTinana: todayAssessment?.tahaTinana || null,
                tahaHinengaro: todayAssessment?.tahaHinengaro || null,
                tahaWairua: todayAssessment?.tahaWairua || null,
                tahaWhanau: todayAssessment?.tahaWhanau || null,
                absent: false,
              }}
              enableReinitialize={true}
              schema={SelfAssessmentSchema}
              onSubmit={handleMetricSubmit}
              innerRef={metricsFormRef}
            >
              <SelfAssessmentFields disabled={!!todayAssessment} />
              {renderAssessmentControls()}
            </Form>
          </Col>
        </Row>
      </AssessmentCard>
      <Row>
        <Col xs>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <h3>Courses</h3>
        </Col>
      </Row>

      {enrolments && bcitoCourses && <Courses enrolments={enrolments} bcitoCourses={bcitoCourses}/>}
      <Dialog open={dialogOpen}>
        <h4>{`Would you like to add a note about Te Whare Tapa Whā?`}</h4>
        <Form
          initialValues={{ note: '' }}
          schema={yup.object({ note: yup.string() })}
          onSubmit={handleNoteSubmit}
        >
          <label>ADD NOTE</label>
          <Text name={'note'} type={''} />
          <MarginButton type={'submit'}>Save assessment</MarginButton>
          <OutlineButton onClick={() => setDialogOpen(false)}>
            Cancel
          </OutlineButton>
        </Form>
      </Dialog>
    </Content>
  );
}
