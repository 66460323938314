import { UserRef } from './User';
import firebase from 'firebase/compat/app';

export interface Course {
  id: string;
  name: string;
  modules: Module[];
  type: CourseType;
}

export enum CourseType {
  BCITO = 'BCITO',
}

export interface Module {
  name: string;
  standards: Standard[];
}

export interface Standard {
  name: string;
  formattedName: string;
  number: number;
  tasks: Task[];
}
export interface EnrolmentTask {
  taskId: string;
  completed: boolean;
  readyForReview: boolean;
  notes: Note[];
}

export interface Note {
  createdAt?: firebase.firestore.Timestamp | firebase.firestore.FieldValue;
  createdBy: UserRef;
  note: string;
  photoRef?: string;
}

export interface Enrolment {
  id: string;
  course: string;
  studentId: string;
  tasks: { [taskId: string]: EnrolmentTask };
  courseId: string;
  ref?: firebase.firestore.DocumentReference;
}

export interface Task {
  completed: boolean;
  name: string;
  id: string;
  number: number;
  formattedName: string;
}
