import { Content } from '../../../../components/Content';
import { Col, Row } from '../../../../components/grid';
import { HeadingXL } from '../../../../components/Heading';
import React, { useState } from 'react';
import StudentOverview from './StudentOverview';
import StudentDetails from './StudentDetails';
import ToggleButtonGroup from '../../../../components/ToggleButtonGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserProfile } from '../../../../hooks/useProfiles';
import Loader from '../../../../components/Loader';
import Breadcrumb from '../../../../components/Breadcrumb';

export default function Student() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string>('overview');
  const { id } = useParams<{ id: string }>();
  const [userProfile, loading] = useUserProfile(id!);

  const optionButtons = [
    { text: 'Overview', value: 'overview' },
    { text: 'Student details', value: 'details' },
  ];
  const onToggleButtonPress = (value: string) => {
    setSelectedTab(value);
  };
  const onStudentsPress = () => {
    navigate(`/profiles/students`);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Content>
      <Row>
        <Col>
          <Breadcrumb text={'Students'} onClick={onStudentsPress} />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <HeadingXL>
            {userProfile?.firstName} {userProfile?.lastName}
          </HeadingXL>
        </Col>
      </Row>
      <Row>
        <Col xs>
          <ToggleButtonGroup
            buttons={optionButtons}
            selectedButton={selectedTab}
            onClick={(value) => onToggleButtonPress(value)}
          />
        </Col>
      </Row>
      {selectedTab === 'overview' ? (
        <StudentOverview profile={userProfile!} />
      ) : (
        <StudentDetails profile={userProfile!} />
      )}
    </Content>
  );
}
