import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import React, { Ref, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Collections } from '../constants/collections';
import { UserProfile } from '../types/UserProfile';
import { OutlineButton, RedButton } from './atoms/Button';
import { Dialog } from './Dialog';

const ButtonGroup = styled.div`
  margin-top: 12px;
  display: flex;
`;

interface DialogProps {
  submitted?: () => void;
  profile: UserProfile;
}

export interface DialogRef {
  show: () => void;
  hide: () => void;
}

function DeleteUserDialog(props: DialogProps, ref: Ref<DialogRef>) {
  const { profile } = props;
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const db = getFirestore();

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const show = () => {
    setOpen(true);
  };

  const hide = () => {
    setOpen(false);
  };
  const onDeleteClick = async (values: any) => {
    await deleteDoc(doc(db, Collections.userProfiles, profile.id));
    navigate(`/profiles/students`);
  };
  return (
    <>
      <Dialog onClose={() => null} open={open}>
        <p>Are you sure you want to delete this user?</p>
        <p>
          Doing so will delete their profile card and remove all related
          information. This cannot be un-done
        </p>
        <ButtonGroup>
          <OutlineButton onClick={() => setOpen(false)}>Cancel</OutlineButton>
          <RedButton onClick={onDeleteClick}>Delete</RedButton>
        </ButtonGroup>
      </Dialog>
    </>
  );
}

export default React.forwardRef(DeleteUserDialog);
