import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEnrolment } from '../../../../../hooks/useEnrolments';
import Loader from '../../../../../components/Loader';
import { Content } from '../../../../../components/Content';
import { useCourse } from '../../../../../hooks/useCourses';
import ListItemLabel from '../../../../../components/ListItemLabel';
import CourseToggleSelect from '../../../../../components/CourseToggleSelect';
import { Standard } from '../../../../../types/Course';
import { ProgressBar } from '../../../../../components/ProgressBar';
import getCourseCompletionStatus from '../../../../../utils/getCourseCompletionStatus';
import { Col, Row } from '../../../../../components/grid';
import styled from 'styled-components';
import SearchInput from '../../../../../components/SearchInput';
import { IconSource } from '../../../../../components/atoms/Icon';
import getCourseInformation from '../../../../../utils/getCourseInformation';
import Breadcrumb from '../../../../../components/Breadcrumb';
import getTasksPending from '../../../../../utils/getTasksPending';

const SearchInputWrapper = styled.div`
  padding: 0px 0 16px;
`;

export default function Enrolment() {
  const { enrolmentId } = useParams<{ id: string; enrolmentId: string }>();
  const navigate = useNavigate();
  const [enrolment, loadingEnrolment] = useEnrolment(enrolmentId);
  const [course, loadingCourse] = useCourse(enrolment?.courseId);
  const [search, setSearch] = useState<string>('');

  if (loadingEnrolment || loadingCourse) {
    return <Loader />;
  }
  const onStandardClick = (standard: Standard) => {
    navigate(
      `/enrolments/${enrolmentId}/${course?.id}/standards/${standard.number}`
    );
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.toLowerCase());
  };
  const completed = enrolment ? getCourseCompletionStatus(enrolment) : 0;
  const pending = enrolment ? getTasksPending(enrolment) : 0;
  const total = course ? getCourseInformation(course) : 0;

  return (
    <Content>
      <Row>
        <Col xs>
          <Breadcrumb onClick={() => navigate(-1)} text={'Profile'} />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <h1>{enrolment?.course}</h1>
        </Col>
      </Row>

      <Row>
        <Col xs>
          <ProgressBar total={total} completed={completed} pending={pending} />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <SearchInputWrapper>
            <SearchInput placeholder={'Search'} onChange={onSearchChange} />
          </SearchInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col xs>
          {course?.modules
            .filter((m) => m.name.toLowerCase().includes(search))
            .map((module, index) => {
              return (
                <div key={`module-${index}`}>
                  <ListItemLabel>{module?.name}</ListItemLabel>

                  {module.standards
                    .filter(
                      (s) =>
                        s.name.toLowerCase().includes(search) ||
                        module.name.toLowerCase().includes(search)
                    )
                    .map((standard: Standard, i) => {
                      const standardCompleted = standard.tasks.every(
                        (t) => (enrolment?.tasks[t.id] || {}).completed
                      );
                      const hasPendingTasks = standard.tasks.some((t) => (enrolment?.tasks[t.id] || {}).readyForReview);
                      return (
                        <CourseToggleSelect
                          key={`standard-${i}`}
                          course={standard.name}
                          number={standard.number}
                          selected={standardCompleted}
                          hasPendingTasks={hasPendingTasks}
                          icon={
                            standardCompleted
                              ? IconSource.save
                              : IconSource.next
                          }
                          onClick={() => onStandardClick(standard)}
                        />
                      );
                    })}
                </div>
              );
            })}
        </Col>
      </Row>
    </Content>
  );
}
