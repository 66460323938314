import styled from 'styled-components';
import { PRIMARY } from '../constants/colours';

type ProgressBarProps = {
  total: number;
  completed: number;
  pending?: number;
};

const InnerDiv = styled.div<ProgressBarProps>`
  background-color: ${PRIMARY};
  width: ${(props) => (props.completed / props.total) * 100}%;
  height: 16px;
`;
const OuterDiv = styled.div`
  background-color: #f4f4f5;
  width: 100%;
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
`;
const BarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const BarDetails = styled.div`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;
const BarDetail = styled.span`
  font-size: 14px;
  color: #999999;
`;

export const ProgressBar = (props: ProgressBarProps) => {
  const tasksPending = props.pending ? `- ${props.pending} pending review` : '';
  return (
    <BarWrapper>
      <BarDetails>
        <BarDetail>
          {props.completed === props.total
            ? 'Completed'
            : `${props.completed}/${props.total} tasks completed ${tasksPending}`}
        </BarDetail>
        <BarDetail>
          {props.completed === props.total
            ? '100%'
            : `${((props.completed / props.total) * 100).toFixed(1)}%`}
        </BarDetail>
      </BarDetails>
      <OuterDiv>
        <InnerDiv total={props.total} completed={props.completed} />
      </OuterDiv>
    </BarWrapper>
  );
}
