const ROUTES = {
  root: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  profiles: '/profiles',
  myProfile: '/profiles/me',
  staff: {
    list: '/profiles/staff',
    invite: '/profiles/staff/invitation',
  },
  courses: '/courses',
  enrolments: {
    overview: '/enrolments/:enrolmentId',
    standard: '/enrolments/:enrolmentId/:id/standards/:standardId',
    task: '/enrolments/:enrolmentId/:courseId/:taskId',
    note: '/enrolments/:enrolmentId/:courseId/:taskId/notes/:noteId',
  },
  students: {
    create: '/profiles/students/add',
    overview: '/profiles/students',
    profile: '/profiles/students/:id',
    assessments: '/profiles/students/:id/history',
    home: '/profiles/students',
    enrolments: '/profiles/students/:id/enrolments'
  },
};

export default ROUTES;
