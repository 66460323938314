import { isInteger } from "lodash";
import styled from "styled-components";
import config, { DIMENSION_NAMES } from "./config";
const offsetProps = DIMENSION_NAMES.map((d) => d + "Offset");

type ColProps = {
  xs?: number | boolean;
  xsOffset?: number;
  sm?: number | boolean;
  smOffset?: number;
  md?: number | boolean;
  mdOffset?: number;
  lg?: number | boolean;
  lgOffset?: number;
};

const Col = styled.div<ColProps>`
  box-sizing: border-box;
  flex: 0 0 auto;
  max-width: 100%;
  ${(p: any) =>
    p.reverse &&
    `
    flex-direction: column-reverse;
  `}
  ${(p: any) =>
    Object.keys(p)
      .filter((k) => ~DIMENSION_NAMES.indexOf(k))
      .sort(
        (k1, k2) => DIMENSION_NAMES.indexOf(k1) - DIMENSION_NAMES.indexOf(k2)
      )
      .map((k) => {
        let style = "display: none;";
        if (isInteger(p[k])) {
          style = `flex-basis: ${(100 / config(p).gridSize) * p[k]}%;
                    max-width: ${(100 / config(p).gridSize) * p[k]}%;
                    display: block;`;
        } else if (p[k]) {
          style =
            "flex-grow: 1; flex-basis: 0; max-width: 100%; display: block;";
        }
        return config(p).media[k](style);
      })}
  ${(p: any) =>
    Object.keys(p)
      .filter((k) => ~offsetProps.indexOf(k))
      .map((k) => {
        const marginLeft = ((100 / config(p).gridSize) * p[k]).toFixed(2);
        const style = `margin-left: ${marginLeft}%`;
        return config(p).media[k.replace(/Offset$/, "")](style);
      })}
`;

Col.displayName = "Col";
export default Col;