import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../components/grid';
import styled from 'styled-components';
import * as yup from 'yup';
import { OutlineButton, PrimaryButton } from '../components/atoms/Button';
import { Content } from '../components/Content';
import { Error, FieldGroup, Form, Input, Label } from '../components/Forms';
import { HeadingL } from '../components/Heading';
import { NEUTRAL_200 } from '../constants/colours';
import { LoadingContext } from '../context/LoadingContext';

const Schema = yup.object({
  email: yup.string().email('Email is not valid').required('Required'),
});

const ButtonGroup = styled.div`
  button:first-of-type {
    margin-bottom: 8px;
  }
`;

const Sent = styled.div`
  background-color: ${NEUTRAL_200};
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 32px;
`;

export default function ForgotPassword() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useContext(LoadingContext);
  const [sent, setSent] = useState<boolean>(false);

  const submit = async (values: { email: string }) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, values.email);
      setSent(true);
    } catch (e) {
      console.log('err', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Content>
      <Row>
        <Col xs>
          <HeadingL>Reset your password</HeadingL>
          <Form initialValues={{ email: '' }} schema={Schema} onSubmit={submit}>
            <FieldGroup>
              <Label forName={'email'}>email address</Label>
              <Input name={'email'} type={'email'} />
              <Error forName={'email'} />
            </FieldGroup>
            {sent && (
              <Sent>
                Request submitted. We've sent a you an email with a link to
                create a new password.
              </Sent>
            )}
            <ButtonGroup>
              <PrimaryButton type={'submit'} disabled={loading || sent}>
                Send me a link
              </PrimaryButton>
              <OutlineButton
                onClick={() => {
                  navigate(-1);
                }}
                disabled={loading}
              >
                Return to login
              </OutlineButton>
            </ButtonGroup>
          </Form>
        </Col>
      </Row>
    </Content>
  );
}
