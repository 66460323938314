import styled from "styled-components";
import { ACTION_TEXT } from "../constants/typography";
import React from "react";
import { Icon, IconSource } from "./atoms/Icon";
import { NO_HIGHLIGHT } from "../constants/styles";

const Text = styled.div`
    ${ACTION_TEXT}
    margin: auto;
    text-decoration: underline;
    text-transform: uppercase;
`;

const Wrapper = styled.div<ActionProps>`
    display: flex;
    width: fit-content;
    cursor: pointer;
    align-items: center;
    
    > * {
        &:last-child {
            margin-left: ${props => props.icon && "8px"};
        }
    }
   
    padding-right: ${props => !props.right && "16px"};
    padding-left: ${props => props.right && "16px"};
    ${NO_HIGHLIGHT};
`;

interface ActionProps {
    text: string;
    icon?: IconSource;
    right?: boolean;
    onClick?: () => void;
}

export default function Action(props: ActionProps) {

    const renderIcon = () => {
        if (props.icon) {
            return <Icon source={props.icon} actionIcon />
        }
    }
    return (
        <Wrapper {...props}>
            {!props.right && renderIcon()}
            <Text>
                {props.text}
            </Text>
            {props.right && renderIcon()}
        </Wrapper>

    )
}
