import React, { useEffect, useState } from 'react';
import { Content } from '../../../components/Content';
import { useNavigate, useParams } from 'react-router-dom';
import { useCourse } from '../../../hooks/useCourses';
import Loader from '../../../components/Loader';
import { Col, Row } from '../../../components/grid';
import { Module } from '../../../types/Course';
import CourseToggleSelect from '../../../components/CourseToggleSelect';
import { IconSource } from '../../../components/atoms/Icon';
import SearchInput from '../../../components/SearchInput';
import styled from 'styled-components';
import { HeadingD } from '../../../components/Heading';
import Breadcrumb from '../../../components/Breadcrumb';
import ListItemLabel from '../../../components/ListItemLabel';

const SearchInputWrapper = styled.div`
  padding: 0px 0 16px;
`;

export default function CourseDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (!id) {
      navigate('/courses', { replace: true });
    }
  }, [id, navigate]);

  const [course, loadingCourse] = useCourse(id!);

  if (loadingCourse) {
    return <Loader />;
  }

  const onModuleClick = (module: Module) => {
    navigate(`/courses/${id}/modules?q=${module.name}`);
  };

  const onCoursesClick = () => {
    navigate('/courses', { replace: true });
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.toLowerCase());
  };

  return (
    <Content>
      <Row>
        <Col xs>
          <Breadcrumb text={'Courses'} onClick={onCoursesClick} />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <HeadingD>{course?.name}</HeadingD>
        </Col>
      </Row>
      <Row>
        <Col xs>
          <SearchInputWrapper>
            <SearchInput placeholder={'Search'} onChange={onSearchChange} />
          </SearchInputWrapper>
        </Col>
      </Row>
      <Row>
        <Col xs>
          <ListItemLabel>{course?.name}</ListItemLabel>
          {course?.modules
            .filter((m) => m.name.toLowerCase().includes(search))
            .map((module: Module) => (
              <CourseToggleSelect
                key={module.name}
                course={module.name}
                icon={IconSource.next}
                onClick={() => onModuleClick(module)}
              />
            ))}
        </Col>
      </Row>
    </Content>
  );
}
