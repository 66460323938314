import {
  addDays,
  eachDayOfInterval,
  format,
  isAfter,
  startOfWeek,
  subDays,
} from 'date-fns';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from './grid';
import styled from 'styled-components';
import { NEUTRAL_200, NEUTRAL_300, NEUTRAL_500 } from '../constants/colours';
import { useUserProfile } from '../hooks/useProfiles';
import { useSelfAssessmentsRange } from '../hooks/useSelfAssessment';
import { Icon, IconSource } from './atoms/Icon';
import Breadcrumb from './Breadcrumb';
import { Content } from './Content';
import { HeadingD } from './Heading';
import LineGraph from './LineGraph';
import Loader from './Loader';
import { DialogRef } from './SelfAssessmentDialog';
import SelfAssessmentResultDialog from './SelfAssessmentResultDialog';

const WellbeingHeader = styled.span`
  display: flex;
  border-collapse: collapse;
  flex-direction: column;
  justify-content: center;
  padding: 8px 8px;
  border-width: 1px 1px 0 1px;

  border-style: solid;
  border-color: #ebebeb;
`;
const WellbeingChartContainer = styled(Col)`
  border-width: 1px;
  border-style: solid;
  border-color: ${NEUTRAL_300};
  padding: 0;
`;

const DateCell = styled.div<{ index: number }>`
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 64px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${NEUTRAL_300};
  padding: 0;
  background-color: ${(props) => (props.index > 4 ? NEUTRAL_200 : 'white')};
`;

const Day = styled.span`
  color: ${NEUTRAL_500};
  font-size: 14px;
  margin-bottom: 8px;
`;
const DateRangeRow = styled(Row)`
  margin: 8px 0 16px 0;
  align-items: center;
`;
const DateHeader = styled.span`
  font-size: 20px;
  width: 150px;
  display: flex;
  flex: 1;
  justify-content: center;
`;
const DateArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
  height: 30px;
`;

const BackButtonRow = styled(Row)`
  margin-top: 24px;
`;

export default function SelfAssessmentHistory() {
  const [startDate, setStartDate] = useState<Date>(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [endDate, setEndDate] = useState<Date>(addDays(startDate, 6));
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dialogRef = useRef<DialogRef>(null);
  const [profile, loadingProfile] = useUserProfile(id!);
  const [selfAssessmentHistory, loading] = useSelfAssessmentsRange(
    id!,
    startDate,
    endDate
  );
  const daysBetween = eachDayOfInterval({ start: startDate, end: endDate }).map(
    (value) => {
      const assessment = selfAssessmentHistory?.find(
        (ass) => ass.date.toDate().getDate() === value.getDate()
      );
      return {
        formattedDateString: format(value, 'PPPPP'),
        date: value.getDate(),
        day: value.toDateString().slice(0, 3).toUpperCase(),
        absent: (assessment || {}).absent,
        tahaTinana: (assessment || {}).tahaTinana,
        tahaWairua: (assessment || {}).tahaWairua,
        tahaWhanau: (assessment || {}).tahaWhanau,
        tahaHinengaro: (assessment || {}).tahaHinengaro,
        note: (assessment || {}).note,
      };
    }
  );
  const RenderDateRow = () => {
    return (
      <div style={{ display: 'flex' }}>
        {daysBetween.map((date, index) => (
          <DateCell
            index={index}
            onClick={() => onCellPress(index)}
            key={date.date}
          >
            <span>{date.date}</span>
            <Day>{date.day}</Day>
            {date.absent && <Icon source={IconSource.absent} actionIcon />}
          </DateCell>
        ))}
      </div>
    );
  };
  const onCellPress = (scoreIndex: number) => {
    setSelectedDay(scoreIndex);
    dialogRef.current?.show();
  };
  const onBackDateClick = () => {
    setStartDate(subDays(startDate, 7));
    setEndDate(subDays(endDate, 7));
  };

  const onNextDateClick = () => {
    setStartDate(addDays(startDate, 7));
    setEndDate(addDays(endDate, 7));
  };

  if (loading || loadingProfile) {
    return <Loader />;
  }
  const tahaTinana = daysBetween.map((day) => day.tahaTinana || 0);
  const tahaWairua = daysBetween.map((day) => day.tahaWairua || 0);
  const tahaWhanau = daysBetween.map((day) => day.tahaWhanau || 0);
  const tahaHinengaro = daysBetween.map((day) => day.tahaHinengaro || 0);

  const thisWeek = isAfter(new Date(), endDate);
  return (
    <Content>
      <BackButtonRow>
        <Col xs>
          <Breadcrumb
            text={`${profile?.firstName} ${profile?.lastName}`}
            onClick={() => navigate(-1)}
          />
        </Col>
      </BackButtonRow>
      <Row>
        <Col xs>
          <HeadingD>Daily report history</HeadingD>
        </Col>
      </Row>

      <DateRangeRow center={'xs'}>
        <Col>
          <DateArrowWrapper onClick={onBackDateClick}>
            <Icon source={IconSource.back} />
          </DateArrowWrapper>
        </Col>
        <Col>
          <DateHeader>{`${startDate!.toDateString().slice(4, 10)} - ${endDate!
            .toDateString()
            .slice(4, 10)}`}</DateHeader>
        </Col>
        <Col>
          <DateArrowWrapper onClick={thisWeek ? onNextDateClick : () => {}}>
            {thisWeek && <Icon source={IconSource.next} />}
          </DateArrowWrapper>
        </Col>
      </DateRangeRow>
      <Row>
        <WellbeingChartContainer xs>
          <RenderDateRow />
          <WellbeingHeader>{'Taha Tinana (Physical)'}</WellbeingHeader>
          <LineGraph
            data={tahaTinana}
            category={'tahaTinana'}
            onCellPress={onCellPress}
          />
          <WellbeingHeader>{'Taha Hinengaro (Mental)'}</WellbeingHeader>
          <LineGraph
            data={tahaHinengaro}
            category={'tahaHinengaro'}
            onCellPress={onCellPress}
          />

          <WellbeingHeader>{'Taha Wairua (Spirit)'}</WellbeingHeader>
          <LineGraph
            data={tahaWairua}
            category={'tahaWairua'}
            onCellPress={onCellPress}
          />

          <WellbeingHeader>{'Taha Whanau (Relationships)'}</WellbeingHeader>
          <LineGraph
            data={tahaWhanau}
            category={'tahaWhanau'}
            onCellPress={onCellPress}
          />
        </WellbeingChartContainer>
      </Row>
      <SelfAssessmentResultDialog
        ref={dialogRef}
        values={daysBetween[selectedDay]}
      />
    </Content>
  );
}
