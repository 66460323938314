import getCourseCompletionStatus from "../utils/getCourseCompletionStatus";
import getCourseInformation from "../utils/getCourseInformation";
import { Col, Row } from "./grid";
import { ProgressBar } from "./ProgressBar";
import React from "react";
import { Course, Enrolment } from "../types/Course";
import { CourseCard } from "./atoms/CourseCard";
import { useNavigate } from "react-router-dom";
import getTasksPending from "../utils/getTasksPending";

export default function Courses(props: { enrolments: Enrolment[], bcitoCourses: Course[] }) {
    const { enrolments, bcitoCourses } = props;
    const navigate = useNavigate();

    const onCourseClick = (enrolmentId: string) => {
        navigate(`/enrolments/${enrolmentId}`);
    };

    return (
        <>
            {enrolments.map((enrolment) => {
                const completed = getCourseCompletionStatus(enrolment);
                const pending = getTasksPending(enrolment);
                const courses = bcitoCourses || [];
                const total = getCourseInformation(
                    courses?.find((c) => c.id === enrolment.courseId)!
                );
                return (
                    <Row key={`course-card-${enrolment.id}`}>
                        <Col xs>
                            <CourseCard onClick={() => onCourseClick(enrolment.id)}>
                                <span>{enrolment.course}</span>
                                <ProgressBar total={total} completed={completed} pending={pending} />
                            </CourseCard>
                        </Col>
                    </Row>
                );
            })}
        </>
    );
}
