import { Content } from '../../../../../../components/Content';
import { Col, Row } from '../../../../../../components/grid';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../../../components/Breadcrumb';
import { useStandard } from '../../../../../../hooks/useCourses';
import styled from 'styled-components';
import Loader from '../../../../../../components/Loader';
import React from 'react';
import { HeadingD } from '../../../../../../components/Heading';

const TaskInfoRow = styled(Row)`
  margin-bottom: 16px;
`;

export default function TaskDetail() {
  const navigate = useNavigate();
  const { id, taskId } = useParams<{ id: string; taskId: string }>();

  const [standardId, taskNumber] = taskId!.split('.');
  const [, , standard, loadingStandard] = useStandard(id!, Number(standardId));
  if (loadingStandard) {
    return <Loader />;
  }

  return (
    <Content>
      <Row>
        <Col xs>
          <Breadcrumb onClick={() => navigate(-1)} text={standardId} />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <HeadingD>
            {standardId} - {taskNumber}
          </HeadingD>
        </Col>
      </Row>
      <TaskInfoRow>
        <Col xs>
          <span>{standard?.tasks[Number(taskNumber) - 1].name}</span>
        </Col>
      </TaskInfoRow>
    </Content>
  );
}
