import { Content } from "../../../../../components/Content";
import { useNavigate, useParams } from "react-router-dom";
import { useUserProfile } from "../../../../../hooks/useProfiles";
import Loader from "../../../../../components/Loader";
import { useEnrolments } from "../../../../../hooks/useEnrolments";
import { useBCITOCourses } from "../../../../../hooks/useCourses";
import { Col, Row } from "../../../../../components/grid";
import React from "react";
import styled from "styled-components";
import Breadcrumb from "../../../../../components/Breadcrumb";
import Courses from "../../../../../components/Courses";

const HeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function Enrolments() {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [userProfile, loadingProfile] = useUserProfile(id!);
    const [enrolments, loadingEnrolments] = useEnrolments(userProfile?.id);
    const [bcitoCourses, loadingBcitoCourses] = useBCITOCourses();

    const onMyProfilePress = () => {
        navigate("/profiles/me");
    };

    if (loadingProfile || loadingEnrolments || loadingBcitoCourses) {
        return <Loader />;
    }

    return (
        <Content>
            <HeaderRow>
                <Col xs>
                    <Breadcrumb text={"My Profile"} onClick={onMyProfilePress} />
                    <h3>My courses</h3>
                </Col>
            </HeaderRow>
            {enrolments && bcitoCourses && <Courses enrolments={enrolments} bcitoCourses={bcitoCourses} />}
        </Content>
    );
}
