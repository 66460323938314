import React, { useEffect, useRef, useState } from 'react';
import { Content } from '../../../components/Content';
import { Col, Row } from '../../../components/grid';
import { HeadingXL } from '../../../components/Heading';
import { useAuth } from '../../../hooks/useAuth';
import { Divider } from '../../../components/atoms/Divider';
import { OutlineButton } from '../../../components/atoms/Button';
import SelfAssessmentDialog, {
  DialogRef,
} from '../../../components/SelfAssessmentDialog';
import {
  DAILY_ASSESSMENT_COMPLETED,
  DAILY_ASSESSMENT_VIEWED,
} from '../../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '../../../types/UserProfile';
import { useStudentProfiles } from '../../../hooks/useProfiles';
import Action from '../../../components/Action';
import { IconSource } from '../../../components/atoms/Icon';
import Loader from '../../../components/Loader';
import styled from 'styled-components';
import {useEnrolments} from "../../../hooks/useEnrolments";
import {Enrolment} from "../../../types/Course";
import {ORANGE_500} from "../../../constants/colours";

const compareAssessmentDate = (localStorageKey: string): boolean => {
  const dateString = window.localStorage.getItem(localStorageKey);
  if (dateString) {
    const viewedDate = new Date(dateString);
    const currentDate = new Date();
    const viewedString = `${viewedDate.getDate()}/${viewedDate.getMonth()}/${viewedDate.getFullYear()}`;
    const currentString = `${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}`;

    if (viewedString === currentString) {
      return true;
    }
  }

  return false;
};

const ActionPadded = styled(Action)`
  padding-top: 32px;
`;
const StudentName = styled.span`
  cursor: pointer;
`;
const StudentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PendingBadge = styled.div`
  text-align: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 0 8px;
  background-color: ${ORANGE_500};
`;

export default function Students() {
  const navigate = useNavigate();
  const [students, loading] = useStudentProfiles();
  const [enrolments] = useEnrolments();
  const enrolmentsWithPendingTasks = enrolments?.filter((enrolment: Enrolment) => {
    const tasks = Object.values(enrolment.tasks);
    return tasks.some(task => task.readyForReview);
  });
  const [user] = useAuth();
  const [assessComplete, setAssessComplete] = useState<boolean>(
    compareAssessmentDate(DAILY_ASSESSMENT_COMPLETED)
  );
  const dialogRef = useRef<DialogRef>(null);
  const onStudentClick = (student: UserProfile) => {
    navigate(`/profiles/students/${student.id}`);
  };

  const onAddPress = () => {
    navigate(`/profiles/students/add`);
  };

  useEffect(() => {
    if (!compareAssessmentDate(DAILY_ASSESSMENT_VIEWED)) {
      dialogRef.current?.show();
      window.localStorage.setItem(
        DAILY_ASSESSMENT_VIEWED,
        new Date().toISOString()
      );
    }
  }, []);

  return (
    <Content>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col xs>
              <HeadingXL>{`Kia ora ${user?.name ?? ''}`}</HeadingXL>
            </Col>
          </Row>
          {!assessComplete && (
            <Row>
              <OutlineButton onClick={() => dialogRef.current?.show()}>
                Assess your Te Whare Tapa Whā
              </OutlineButton>
            </Row>
          )}
          <Row>
            <Col xs>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col xs>
              <h3>Find a student</h3>
            </Col>
            <Col>
              <ActionPadded
                text={'new'}
                icon={IconSource.new}
                right
                onClick={onAddPress}
              />
            </Col>
          </Row>
          <Row>
            <Col xs>
              <Divider />
            </Col>
          </Row>
          {students?.map((student, index) => {
            const hasPendingTasks = enrolmentsWithPendingTasks?.some(enrolment => enrolment.studentId === student.id)
            return (
              <Row
                onClick={() => onStudentClick(student)}
                key={`student-${index}`}
              >
                <Col xs>
                  <StudentWrapper>
                    <StudentName>
                      {student?.firstName} {student?.lastName}
                    </StudentName>
                    {hasPendingTasks && <PendingBadge>!</PendingBadge>}
                  </StudentWrapper>
                  <Divider />
                </Col>
              </Row>
            )
          })}
        </>
      )}
      <SelfAssessmentDialog
        ref={dialogRef}
        onAssessmentCompleted={() => setAssessComplete(true)}
      />
    </Content>
  );
}
