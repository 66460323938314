import { Col, Row } from '../../../../components/grid';
import {
  Error,
  FieldGroup,
  Form,
  Input,
  Label,
  Text,
} from '../../../../components/Forms';
import {
  OutlineButton,
  PrimaryButton,
  RedOutlineButton,
} from '../../../../components/atoms/Button';
import LabeledValueRow from '../../../../components/LabeledValueRow';
import { useState, useRef } from 'react';
import * as yup from 'yup';
import { UserProfile } from '../../../../types/UserProfile';

import Loader from '../../../../components/Loader';
import { Collections } from '../../../../constants/collections';
import DeleteUserDialog, {
  DialogRef,
} from '../../../../components/DeleteUserDialog';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { ButtonGroup } from '../../../../components/ButtonGroup';

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email('Invalid email').required('Required'),
  address: yup.string().required('Required'),
  phone: yup.string().required('Required'),
  notes: yup.string(),
});

interface StudentDetailsProps {
  profile: UserProfile;
}

export default function StudentDetails(props: StudentDetailsProps) {
  const { profile } = props;
  const db = getFirestore();
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const dialogRef = useRef<DialogRef>(null);

  const onEditClick = () => {
    setEditing(true);
  };

  const onSaveDetails = async (values: any) => {
    setLoading(true);
    await setDoc(doc(db, Collections.userProfiles, profile!.id), values);

    setEditing(false);
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Row>
        <Col xs>
          <h3>Student details</h3>
        </Col>
      </Row>
      {!editing ? (
        <>
          <LabeledValueRow
            label={'Full name'}
            value={`${profile?.firstName} ${profile?.lastName}`}
          />
          <LabeledValueRow label={'Email address'} value={profile?.email} />
          <LabeledValueRow label={'Contact number'} value={profile?.phone} />
          <LabeledValueRow label={'Address'} value={profile?.address} />
          <LabeledValueRow label={'Notes'} value={profile?.notes} />
          <OutlineButton onClick={onEditClick}>Edit details</OutlineButton>
        </>
      ) : (
        <Form
          initialValues={profile}
          schema={validationSchema}
          onSubmit={(values) => onSaveDetails(values)}
        >
          <Row>
            <Col xs>
              <FieldGroup>
                <Label forName={'firstName'}>First Name</Label>
                <Input name={'firstName'} type={'text'} />
                <Error forName={'firstName'} />
              </FieldGroup>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <FieldGroup>
                <Label forName={'lastName'}>Last Name</Label>
                <Input name={'lastName'} type={'text'} />
                <Error forName={'lastName'} />
              </FieldGroup>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <FieldGroup>
                <Label forName={'email'}>Email Address</Label>
                <Input name={'email'} type={'email'} />
                <Error forName={'email'} />
              </FieldGroup>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <FieldGroup>
                <Label forName={'phone'}>Contact Number</Label>
                <Input name={'phone'} type={'number tel'} />
                <Error forName={'phone'} />
              </FieldGroup>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <FieldGroup>
                <Label forName={'address'}>Address</Label>
                <Input name={'address'} type={'text'} />
                <Error forName={'address'} />
              </FieldGroup>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <FieldGroup>
                <Label forName={'notes'}>Notes</Label>
                <Text
                  name={'notes'}
                  type={'text'}
                  placeholder={'Add a note about this student...'}
                />
                <Error forName={'notes'} />
              </FieldGroup>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <ButtonGroup>
                <PrimaryButton type={'submit'}>Save details</PrimaryButton>
                <RedOutlineButton onClick={() => dialogRef.current?.show()}>
                  Delete Student
                </RedOutlineButton>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
      )}
      <DeleteUserDialog ref={dialogRef} profile={profile} />
    </>
  );
}
