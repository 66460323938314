import { addDoc, collection, getFirestore } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "../../../../components/grid";
import styled from "styled-components";
import * as yup from "yup";
import Action from "../../../../components/Action";
import { PrimaryButton } from "../../../../components/atoms/Button";
import { Divider } from "../../../../components/atoms/Divider";
import { IconSource } from "../../../../components/atoms/Icon";
import { Content } from "../../../../components/Content";
import CourseToggleSelect from "../../../../components/CourseToggleSelect";
import {
    Error,
    FieldGroup,
    Form,
    Input,
    Label,
    Text,
} from "../../../../components/Forms";
import { HeadingL } from "../../../../components/Heading";
import Loader from "../../../../components/Loader";
import { Collections } from "../../../../constants/collections";
import { useCourses } from "../../../../hooks/useCourses";
import { UserRole } from "../../../../types/User";
import createStudentEnrolment from "../../../../utils/createStudentEnrolment";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { getFunctions } from "firebase/functions";
import { getApp } from "firebase/app";

const validationSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    phone: yup.string().required("Required"),
    address: yup.string().required("Required"),
    notes: yup.string(),
});

const ActionPadded = styled(Action)`
  padding-top: 32px;
`;

export default function AddNewStudent() {
    const db = getFirestore();
    const [selectedCourses, setSelectedCourses] = useState<any[]>([]);
    const navigate = useNavigate();
    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        notes: "",
    };
    const [courses, loading] = useCourses();
    const [encode] = useHttpsCallable(getFunctions(getApp()), "encodeRole");


    const onSubmit = async (values: any) => {
        values.role = UserRole.student;
        const toEnrol = courses!.filter((course) =>
            selectedCourses.includes(course.name)
        );

        const result = await encode({
            role: values.role,
            email: values.email,
        });

        const encodedRole = result!.data;

        addDoc(collection(db, Collections.userProfiles), values)
            .then(async (docRef) => {
                if (toEnrol.length) {
                    const enrolments = toEnrol.map((course) =>
                        createStudentEnrolment(course!, docRef.id)
                    );
                    enrolments.forEach((enrolment) => {
                        addDoc(collection(db, Collections.enrolments), enrolment);
                    });
                }

                const origin = window.location.origin;
                const url = `${origin}/login?firstName=${values.firstName}&lastName=${values.lastName}&phone=${values.phone}&address=${values.address}&user=${encodedRole}`;
                await sendSignInLinkToEmail(getAuth(), values.email, {
                    url: url,
                    handleCodeInApp: true,
                }).catch((e: any) =>
                    console.log("firebase sign in link error: ", e.code)
                );
                navigate(`/profiles/students/${docRef.id}`, { replace: true });
            })
            .catch((error) => {
                console.log("Error adding user profile:", error);
            });
    };

    const onStudentsPress = () => {
        navigate(`/profiles/students`);
    };

    const onCourseClick = (courseName: any) => {
        let newSelectedCourses = [...selectedCourses];

        if (!selectedCourses.length) {
            newSelectedCourses.push(courseName);
            setSelectedCourses(newSelectedCourses);
            return;
        }

        if (selectedCourses.includes(courseName)) {
            newSelectedCourses.splice(newSelectedCourses.indexOf(courseName), 1);
            setSelectedCourses(newSelectedCourses);
            return;
        }

        newSelectedCourses.push(courseName);
        setSelectedCourses(newSelectedCourses);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Content>
            <Row>
                <Col>
                    <ActionPadded
                        text={"Students"}
                        icon={IconSource.back}
                        onClick={onStudentsPress}
                    />
                </Col>
            </Row>
            <Form
                initialValues={initialValues}
                schema={validationSchema}
                onSubmit={(values) => onSubmit(values)}
            >
                <Row>
                    <Col xs>
                        <HeadingL>Add new student</HeadingL>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <h4>Student details</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"firstName"}>First Name</Label>
                            <Input name={"firstName"} type={"text"} />
                            <Error forName={"firstName"} />
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"lastName"}>Last Name</Label>
                            <Input name={"lastName"} type={"text"} />
                            <Error forName={"lastName"} />
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"email"}>Email Address</Label>
                            <Input name={"email"} type={"email"} />
                            <Error forName={"email"} />
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"phone"}>Contact Number</Label>
                            <Input name={"phone"} type={"number tel"} />
                            <Error forName={"phone"} />
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"address"}>Address</Label>
                            <Input name={"address"} type={"text"} />
                            <Error forName={"address"} />
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FieldGroup>
                            <Label forName={"notes"}>Notes</Label>
                            <Text
                                name={"notes"}
                                type={"text"}
                                placeholder={"Add a note about this student..."}
                            />
                            <Error forName={"notes"} />
                        </FieldGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <Divider />
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <h4>Student enrolments</h4>
                    </Col>
                </Row>

                {courses?.map((course, index) => {
                    const selected = selectedCourses.includes(course.name);
                    return (
                        <Row key={course.id}>
                            <Col xs>
                                <Label forName={"courses"}>{course.name}</Label>
                                <CourseToggleSelect
                                    course={course.name}
                                    icon={selected ? IconSource.save : IconSource.new}
                                    onClick={() => onCourseClick(course.name)}
                                    selected={selected}
                                />
                            </Col>
                        </Row>
                    );
                })}
                <Row>
                    <Col xs>
                        <PrimaryButton type={"submit"}>Save & Continue</PrimaryButton>
                    </Col>
                </Row>
            </Form>
        </Content>
    );
}
