import { getAuth } from "firebase/auth";
import { createContext, useContext, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "../types/User";

export const UserContext = createContext<[User | null, boolean]>([null, true]);

export const UserProvider: React.FC = ({ children }) => {
    useAuthState(getAuth(), {
        onUserChanged: async (firebaseUser) => {
            if (firebaseUser) {
                const result = await firebaseUser.getIdTokenResult();
                let claims = result.claims;

                if (!claims.role) {
                    const refreshedToken = await firebaseUser.getIdTokenResult(
                        true
                    );
                    claims = refreshedToken.claims;
                }

                const user = {
                    uid: firebaseUser?.uid,
                    name: firebaseUser?.displayName,
                    email: firebaseUser?.email,
                    role: claims.role,
                } as User;

                setUser(user);
            } else {
                setUser(null);
            }
        },
    });
    const [user, setUser] = useState<User | null | undefined>(undefined);
    const loading = user === undefined;

    return (
        <UserContext.Provider value={[user || null, loading]}>
            {children}
        </UserContext.Provider>
    );
};

export const useAuth = (): [User | null, boolean] => {
    return useContext(UserContext);
};
