import { doc, getFirestore, updateDoc } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "../../../../../../../components/grid";
import styled from "styled-components";
import { OutlineButton, PrimaryButton, SecondaryButton } from "../../../../../../../components/atoms/Button";
import Label from "../../../../../../../components/atoms/Label";
import Breadcrumb from "../../../../../../../components/Breadcrumb";
import { ButtonGroup } from "../../../../../../../components/ButtonGroup";
import { Content } from "../../../../../../../components/Content";
import { HeadingD } from "../../../../../../../components/Heading";
import { Inset } from "../../../../../../../components/InsetText";
import Loader from "../../../../../../../components/Loader";
import { Collections } from "../../../../../../../constants/collections";
import { useStandard } from "../../../../../../../hooks/useCourses";
import { useEnrolment } from "../../../../../../../hooks/useEnrolments";
import { Note } from "../../../../../../../types/Course";
import { useAuth } from "../../../../../../../hooks/useAuth";
import { UserRole } from "../../../../../../../types/User";

const TaskInfoRow = styled(Row)`
  margin-bottom: 16px;
`;

export default function EnrolmentTaskDetail() {
  const navigate = useNavigate();
  const [user] = useAuth();
  const { enrolmentId, courseId, taskId } =
    useParams<{ enrolmentId: string; courseId: string; taskId: string }>();
  const [standardId, taskNumber] = taskId!.split('.');

  const [, , standard, loadingStandard] = useStandard(
    courseId!,
    Number(standardId)
  );
  const [enrolment, loadingEnrolment] = useEnrolment(enrolmentId!);

  if (loadingStandard || loadingEnrolment || !enrolment) {
    return <Loader />;
  }

  const completed = enrolment.tasks[taskId!]?.completed;
  const readyForReview = enrolment.tasks[taskId!]?.readyForReview;
  const notes = enrolment.tasks[taskId!]?.notes || [];

  const onCompleted = (completed: boolean) => {
    if (taskId) {
      enrolment.tasks[taskId] = {
        ...enrolment.tasks[taskId],
        completed,
      };
      updateDoc(doc(getFirestore(), Collections.enrolments, enrolment.id), {
        tasks: enrolment.tasks,
      });
    }
  };

  const onReadyForReview = (readyForReview: boolean) => {
    if (taskId) {
      enrolment.tasks[taskId] = {
        ...enrolment.tasks[taskId],
        readyForReview,
      };
      updateDoc(doc(getFirestore(), Collections.enrolments, enrolment.id), {
        tasks: enrolment.tasks,
      });
    }
  };

  const onMarkAsCompleted = () => {
    onCompleted(true);
    onReadyForReview(false);
  };

  const onMarkAsIncomplete = () => {
    onCompleted(false);
    onReadyForReview(false);
  };

  const onMarkReadyForReview = () => {
    onReadyForReview(true);
  }

  const onAddNote = () => {
    navigate('notes/-1');
  };

  const onViewNote = (i: number) => {
    navigate(`notes/${i}`);
  };

  return (
    <Content>
      <>
        <Row>
          <Col xs>
            <Breadcrumb onClick={() => navigate(-1)} text={standardId} />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <HeadingD>
              {standardId} - {taskNumber}
            </HeadingD>
          </Col>
        </Row>
        <TaskInfoRow>
          <Col xs>
            <span>{standard?.tasks[Number(taskNumber) - 1].name}</span>
          </Col>
        </TaskInfoRow>
        <Row>
          <Col xs>
            {!!notes.length && <Label>Assessment notes</Label>}
            {notes.map((note: Note, index) => {
              return (
                <Inset key={`note-${index}`} onClick={() => onViewNote(index)}>
                  <Label>{`${note.createdBy.name}`}</Label>
                  <span>{note.note}</span>
                </Inset>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col xs></Col>
        </Row>
      </>
      <Row>
        <Col xs>
          <ButtonGroup>
            {user?.role !== UserRole.student && readyForReview && (
              <>
                <SecondaryButton onClick={onMarkAsIncomplete}>
                  Mark as incomplete
                </SecondaryButton>
                <PrimaryButton onClick={onMarkAsCompleted}>
                  Mark as completed
                </PrimaryButton>
              </>
            )}
            <OutlineButton onClick={onAddNote}>Add note or photo</OutlineButton>
            {user?.role === UserRole.student && !completed && (
              <PrimaryButton disabled={readyForReview} onClick={onMarkReadyForReview}>Submit for review</PrimaryButton>
            )}
          </ButtonGroup>
        </Col>
      </Row>
    </Content>
  );
}
