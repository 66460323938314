import styled from "styled-components";
import { NEUTRAL_300 } from "../../constants/colours";

export const CourseCard = styled.div`
  height: 108px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${NEUTRAL_300};
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  justify-content: center;
  margin: 8px 0;
  cursor: pointer;
`;
