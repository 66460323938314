import {
    useCollectionDataOnce,
    useDocumentData,
} from "react-firebase-hooks/firestore";

import { Collections } from "../constants/collections";
import { Course, CourseType, Module, Standard } from "../types/Course";
import {
    collection,
    doc,
    FirestoreDataConverter,
    getFirestore,
    orderBy,
    query,
    where,
} from "firebase/firestore";

const courseConverter: FirestoreDataConverter<Course> = {
    toFirestore(course: Course) {
        return course;
    },
    fromFirestore(snapshot, options): Course {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id,
        } as Course;
    },
};

const courses = () => {
    const db = getFirestore();
    return collection(db, Collections.courses).withConverter(courseConverter);
};

const course = (id: string | undefined) => {
    const db = getFirestore();
    return id
        ? doc(db, Collections.courses, id).withConverter(courseConverter)
        : undefined;
};

export function useCourses() {
    return useCollectionDataOnce<Course>(courses());
}

export function useBCITOCourses() {
    const q = query(
        courses(),
        where("type", "==", CourseType.BCITO),
        orderBy("name")
    );
    return useCollectionDataOnce<Course>(q);
}

export function useCourse(id: string | undefined) {
    return useDocumentData<Course>(course(id));
}

export const useStandard = (
    courseId: string,
    standardNumber: number
): [Course | undefined, Module | undefined, Standard | undefined, boolean] => {
    const [course, loadingCourse] = useCourse(courseId);
    let standard: Standard | undefined = undefined;
    let module: Module | undefined = undefined;

    if (!loadingCourse && !isNaN(standardNumber)) {
        course?.modules.forEach((m) => {
            const filteredStandards: Standard[] = m.standards.filter((s) => {
                return s.number === standardNumber;
            });

            if (filteredStandards.length > 0) {
                standard = filteredStandards[0];
                module = m;
                return;
            }
        });
    }

    return [course, module, standard, loadingCourse];
};
