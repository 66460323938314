export const HEADING_EXTRA_LARGE =
  "font-family: 'Open Sans'; font-size: 36px; line-height: normal;";
export const HEADING_LARGE =
  "font-family: 'Open Sans'; font-size: 30px; line-height: normal;";
export const HEADING_DEFAULT =
  "font-family: 'Open Sans'; font-size: 26px; line-height: 26px;";
export const HEADING_SMALL =
  "font-family: 'Open Sans'; font-size: 20px; line-height: normal;";

export const BODY_DEFAULT =
  "font-family: 'Open Sans'; font-size: 16px; line-height: normal;";
export const BODY_LARGE =
  "font-family: 'Open Sans'; font-size: 18px; line-height: normal;";
export const BODY_SMALL =
  "font-family: 'Open Sans'; font-size: 14px; line-height: normal;";
export const BODY_SMALLEST =
  "font-family: 'Open Sans'; font-size: 12px; line-height: normal;";

export const ACTION_TEXT =
  "font-family: 'Open Sans'; font-size: 14px; line-height: normal;";

export const LABEL =
  "font-family: 'Open Sans'; font-size: 16px; line-height: normal;";

export const STRAPLINE =
  "font-family: 'Open Sans'; font-size: 12px; line-height: normal;";
