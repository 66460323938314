import styled, { css } from "styled-components";
import config, { DIMENSION_NAMES } from "./config";
import { PropsWithChildren } from "react";

type GridProps = {
  fluid?: boolean;
};

const Grid = styled.div<PropsWithChildren<GridProps>>`
  margin-right: auto;
  margin-left: auto;
  ${(p) =>
    !p.fluid &&
    css`
      ${DIMENSION_NAMES.map(
        (t) =>
          config(p).container[t] &&
          config(p).media[t]`
        width: ${(p: any) => config(p).container[t]}rem;
      `
      )}
    `}
`;

Grid.displayName = "Grid";
export default Grid;