import React, { PropsWithChildren, useContext } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import OverlayLoader from "./components/OverlayLoader";
import SelfAssessmentHistory from "./components/SelfAssessmentHistory";
import ROUTES from "./constants/routes";
import { LoadingContext, LoadingProvider } from "./context/LoadingContext";
import { GlobalStyle } from "./GlobalStyle";
import { useAuth } from "./hooks/useAuth";
import AllCourses from "./routes/courses/allCourses/AllCourses";
import CourseDetail from "./routes/courses/courseDetail/CourseDetail";
import ModuleDetail from "./routes/courses/courseDetail/moduleDetail/ModuleDetail";
import StandardDetail from "./routes/courses/courseDetail/moduleDetail/standardDetail/StandardDetail";
import TaskDetail from "./routes/courses/courseDetail/moduleDetail/standardDetail/taskDetail/TaskDetail";
import ForgotPassword from "./routes/ForgotPassword";
import { Login } from "./routes/Login";
import MyProfile from "./routes/profiles/MyProfile";
import StaffInvite from "./routes/profiles/staff/StaffInvite";
import StaffList from "./routes/profiles/staff/StaffList";
import StaffProfile from "./routes/profiles/staff/StaffProfile";
import AddNewStudent from "./routes/profiles/students/addNewStudent/AddNewStudent";
import Enrolment from "./routes/profiles/students/student/enrolment/Enrolment";
import EnrolmentDetail from "./routes/profiles/students/student/enrolment/enrolmentDetail/EnrolmentDetail";
import EnrolmentTaskDetail
    from "./routes/profiles/students/student/enrolment/enrolmentDetail/enrolmentTaskDetail/EnrolmentTaskDetail";
import EnrolmentTaskNote
    from "./routes/profiles/students/student/enrolment/enrolmentDetail/enrolmentTaskDetail/EnrolmentTaskNote";
import Enrolments from "./routes/profiles/students/student/enrolment/Enrolments";
import Student from "./routes/profiles/students/student/Student";
import Students from "./routes/profiles/students/Students";
import { UserRole } from "./types/User";

function App() {
    const [appLoading] = useContext(LoadingContext);
    const [user, loading] = useAuth();

    if (loading) {
        return null;
    }

    return (
        <>
            <GlobalStyle />
            <OverlayLoader loading={appLoading} />
            <NavBar />
            <LoadingProvider>
                <Routes>
                    <Route path={ROUTES.login} element={<Login />} />
                    <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} />

                    <Route path={"/"} element={<AuthenticatedOutlet />}>
                        <Route path={ROUTES.myProfile} element={<MyProfile />} />

                        <Route path={"/courses"} element={(
                            <StaffRoute>
                                <AllCourses />
                            </StaffRoute>
                        )} />
                        <Route path={ROUTES.students.create} element={(
                            <StaffRoute>
                                <AddNewStudent />
                            </StaffRoute>
                        )} />
                        <Route path={ROUTES.enrolments.overview} element={(
                            <Enrolment />
                        )} />
                        <Route
                            path={ROUTES.enrolments.standard}
                            element={<EnrolmentDetail />}
                        />
                        <Route
                            path={ROUTES.enrolments.task}
                            element={<EnrolmentTaskDetail />}
                        />
                        <Route
                            path={ROUTES.enrolments.note}
                            element={<EnrolmentTaskNote />}
                        />

                        <Route path={ROUTES.students.profile} element={(
                            <StaffRoute>
                                <Student />
                            </StaffRoute>
                        )} />
                        <Route
                            path={ROUTES.students.assessments}
                            element={<SelfAssessmentHistory />}
                        />
                        <Route path={ROUTES.students.home} element={<StaffRoute><Students /></StaffRoute>} />
                        <Route path={ROUTES.staff.invite} element={<StaffRoute><StaffInvite /></StaffRoute>} />
                        <Route
                            path={"/profiles/staff/:id/history"}
                            element={<StaffRoute><SelfAssessmentHistory /></StaffRoute>}
                        />
                        <Route path={"/profiles/staff/:id"} element={<StaffRoute><StaffProfile /></StaffRoute>} />
                        <Route path={ROUTES.staff.list} element={<StaffRoute><StaffList /></StaffRoute>} />
                        <Route path={"/courses/:id"} element={<StaffRoute><CourseDetail /></StaffRoute>} />
                        <Route path={"/courses/:id/modules"} element={<StaffRoute><ModuleDetail /></StaffRoute>} />
                        <Route
                            path={"/courses/:id/standards/:standardId"}
                            element={<StaffRoute><StandardDetail /></StaffRoute>}
                        />

                        <Route
                            path={"/courses/:id/tasks/:taskId"}
                            element={<StaffRoute><TaskDetail /></StaffRoute>}
                        />

                        <Route path={ROUTES.students.enrolments} element={<Enrolments/>}/>

                        <Route
                            path="/"
                            element={user?.role === UserRole.student ? (
                                    <Navigate replace to={`/profiles/me`} />
                                )
                                :
                                (
                                    <Navigate replace to={"/profiles/students"} />
                                )
                            }
                        />

                    </Route>
                </Routes>
            </LoadingProvider>
        </>
    );
}

const StaffRoute = (props: PropsWithChildren<any>) => {
    const [user] = useAuth();

    if (user && user.role !== UserRole.student) {
        return props.children;
    }

    return <Navigate replace to="/profiles/me" />;
};

const AuthenticatedOutlet = () => {
    const [user] = useAuth();

    if (user) {
        return <Outlet />;
    }

    return <Navigate to="/login" />;
};

export default App;
