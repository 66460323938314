import { Enrolment, EnrolmentTask} from "../types/Course";


export default function getTasksPending(enrolment: Enrolment) {
  let pendingCount = 0;


  Object.values(enrolment.tasks).forEach((task: EnrolmentTask) => {
    if (task.readyForReview) {
      pendingCount += 1;
    }

  });

  return pendingCount;
}
