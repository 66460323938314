import styled from 'styled-components';
import MaterialDialog from '@material-ui/core/Dialog';

export const Dialog = styled(MaterialDialog)`
  .MuiDialog-container {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .MuiDialog-paper {
    padding: 16px;
    max-height: calc(70vh);
    max-width: 80%;
    width: 100%;

    @media screen and (min-width: 768px) {
      max-width: 500px;
    }
  }
`;
