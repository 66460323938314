import {
    collection,
    doc,
    FirestoreDataConverter,
    getFirestore,
    limit,
    query,
    where,
} from "firebase/firestore";
import {
    useCollectionDataOnce,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import { Collections } from "../constants/collections";
import { UserRole } from "../types/User";
import { UserProfile } from "../types/UserProfile";

const profileConverter: FirestoreDataConverter<UserProfile> = {
    toFirestore(profile: UserProfile) {
        return profile;
    },
    fromFirestore(snapshot, options): UserProfile {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id,
        } as UserProfile;
    },
};

const profiles = () => {
    const db = getFirestore();
    return collection(db, Collections.userProfiles).withConverter(
        profileConverter
    );
};

export const useUserProfileForUser = (uid: string | undefined) => {
    const [list] = useCollectionDataOnce<UserProfile>(
        uid ? query(profiles(), where("uid", "==", uid), limit(1)) : null
    );
    return useUserProfile(list && list[0] ? list[0].id : undefined);
};

export const useUserProfile = (pid: string | undefined) => {
    return useDocumentData<UserProfile>(pid ? doc(profiles(), pid) : null);
};

export const useStaffProfiles = () => {
    const q = query(
        profiles(),
        where("role", "in", [UserRole.tutor])
    );
    return useCollectionDataOnce<UserProfile>(q);
};

export const useStudentProfiles = () => {
    const q = query(profiles(), where("role", "in", [UserRole.student]));
    return useCollectionDataOnce<UserProfile>(q);
};
