import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from '../../../components/grid';
import styled from 'styled-components';
import * as yup from 'yup';
import Action from '../../../components/Action';
import { AssessmentCard } from '../../../components/AssessmentCard';
import {
  OutlineButton,
  PrimaryButton,
  RedOutlineButton,
} from '../../../components/atoms/Button';
import { IconSource } from '../../../components/atoms/Icon';
import Breadcrumb from '../../../components/Breadcrumb';
import { Content } from '../../../components/Content';
import DeleteUserDialog, {
  DialogRef,
} from '../../../components/DeleteUserDialog';
import {
  Error,
  FieldGroup,
  Form,
  Input,
  Label,
} from '../../../components/Forms';
import { HeadingD, HeadingL } from '../../../components/Heading';
import LabeledValueRow from '../../../components/LabeledValueRow';
import SelfAssessmentBar from '../../../components/SelfAssessmentBar';
import { Collections } from '../../../constants/collections';
import { NEUTRAL_200 } from '../../../constants/colours';
import Routes from '../../../constants/routes';
import { useAuth } from '../../../hooks/useAuth';
import { useUserProfile } from '../../../hooks/useProfiles';
import { useSelfAssessment } from '../../../hooks/useSelfAssessment';
import { UserRole } from '../../../types/User';

const EditSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string().email(),
  phone: yup.string(),
});

type ProfileValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

const AssessmentNote = styled.div`
  background-color: ${NEUTRAL_200};
  padding: 2px 16px;
  box-sizing: border-box;
  border-radius: 8px;
`;
const SaveButton = styled(PrimaryButton)`
  margin: 8px 0;
`;

interface Props {
  myProfile?: boolean;
}

export default function StaffProfile(props: Props) {
  const { id: profileId } = useParams<{ id: string }>();
  const [user] = useAuth();
  const navigate = useNavigate();
  const [profile, loadingProfile] = useUserProfile(profileId);
  const [todayAssessment] = useSelfAssessment(profileId, new Date());
  const [editing, setEditing] = useState<boolean>(false);

  const dialogRef = useRef<DialogRef>(null);

  const submit = async (values: ProfileValues) => {
    try {
      if (profile?.id) {
        const db = getFirestore();
        await updateDoc(doc(db, Collections.userProfiles, profile.id), values);
        setEditing(false);
      }
    } catch (e) {
      console.log('err', e);
    }
  };

  return (
    <Content>
      <Row>
        <Col xs>
          <Breadcrumb
            text={'Staff'}
            onClick={() => navigate(Routes.staff.list)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs>
          {!loadingProfile && (
            <HeadingL>{`${profile?.firstName} ${profile?.lastName}`}</HeadingL>
          )}
        </Col>
      </Row>
      {user?.role === UserRole.administrator && (
        <>
          <Row>
            <Col xs>
              <h3>Daily report</h3>
            </Col>
            <Col>
              <Action
                text={'history'}
                icon={IconSource.next}
                right
                onClick={() =>
                  navigate(`${Routes.staff.list}/${profileId}/history`)
                }
              />
            </Col>
          </Row>
          <AssessmentCard>
            <Row>
              <Col xs>
                {todayAssessment ? (
                  <>
                    <SelfAssessmentBar
                      label={'Taha Tinana (Physical)'}
                      value={todayAssessment.tahaTinana}
                    />
                    <SelfAssessmentBar
                      label={'Taha Hinengaro (Mental)'}
                      value={todayAssessment?.tahaHinengaro}
                    />
                    <SelfAssessmentBar
                      label={'Taha Wairua (Spiritual)'}
                      value={todayAssessment?.tahaWairua}
                    />
                    <SelfAssessmentBar
                      label={'Taha Whanau (Relationships)'}
                      value={todayAssessment?.tahaWhanau}
                    />
                    {todayAssessment.note && (
                      <AssessmentNote>
                        <p>{todayAssessment?.note}</p>
                      </AssessmentNote>
                    )}
                  </>
                ) : (
                  <p>{`${profile?.firstName} has not entered their Te Whare Tapa Wha today`}</p>
                )}
              </Col>
            </Row>
          </AssessmentCard>
        </>
      )}
      <Row>
        <Col xs>
          <HeadingD>Details</HeadingD>
          {editing ? (
            <Form
              initialValues={{
                firstName: profile?.firstName,
                lastName: profile?.lastName,
                email: profile?.email,
                phone: profile?.phone,
              }}
              schema={EditSchema}
              onSubmit={submit}
              enableReinitialize={true}
            >
              <FieldGroup>
                <Label forName={'firstName'}>first name</Label>
                <Input name={'firstName'} type={'text'} />
                <Error forName={'firstName'} />
              </FieldGroup>
              <FieldGroup>
                <Label forName={'lastName'}>last name</Label>
                <Input name={'lastName'} type={'text'} />
                <Error forName={'lastName'} />
              </FieldGroup>
              <FieldGroup>
                <Label forName={'email'}>email address</Label>
                <Input name={'email'} type={'email'} />
                <Error forName={'email'} />
              </FieldGroup>
              <FieldGroup>
                <Label forName={'phone'}>contact number</Label>
                <Input name={'phone'} type={'tel'} />
                <Error forName={'phone'} />
              </FieldGroup>
              <SaveButton type={'submit'}>Save</SaveButton>
              <OutlineButton onClick={() => setEditing(false)}>
                Cancel
              </OutlineButton>
              <RedOutlineButton onClick={() => dialogRef.current?.show()}>
                Delete user
              </RedOutlineButton>
            </Form>
          ) : (
            <>
              <LabeledValueRow
                label={'full name'}
                value={`${profile?.firstName} ${profile?.lastName}`}
              />
              <LabeledValueRow label={'email address'} value={profile?.email} />
              <LabeledValueRow
                label={'contact number'}
                value={profile?.phone}
              />
              <LabeledValueRow label={'role'} value={profile?.role} />
              {(user?.uid === profile?.uid ||
                user?.role === UserRole.administrator) && (
                <OutlineButton onClick={() => setEditing(true)}>
                  Edit details
                </OutlineButton>
              )}
            </>
          )}
        </Col>
      </Row>
      <DeleteUserDialog ref={dialogRef} profile={profile!} />
    </Content>
  );
}
