import { doc, getFirestore, updateDoc } from "firebase/firestore";
import {
    deleteObject,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes,
} from "firebase/storage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "../../../../../../../components/grid";
import * as yup from "yup";
import {
    OutlineButton,
    PrimaryButton,
    RedButton,
    RedOutlineButton,
} from "../../../../../../../components/atoms/Button";
import Breadcrumb from "../../../../../../../components/Breadcrumb";
import { ButtonGroup } from "../../../../../../../components/ButtonGroup";
import { Content } from "../../../../../../../components/Content";
import { Dialog } from "../../../../../../../components/Dialog";
import {
    Form,
    Label,
    PhotoSelect,
    Text,
} from "../../../../../../../components/Forms";
import { HeadingD } from "../../../../../../../components/Heading";
import Loader from "../../../../../../../components/Loader";
import { Collections } from "../../../../../../../constants/collections";
import { useAuth } from "../../../../../../../hooks/useAuth";
import { useStandard } from "../../../../../../../hooks/useCourses";
import { useEnrolment } from "../../../../../../../hooks/useEnrolments";
import { format } from "date-fns";
import { Note } from "../../../../../../../types/Course";
import styled from "styled-components";
import { useUserProfileForUser } from "../../../../../../../hooks/useProfiles";

const Photo = styled.img`
    padding: 8px;
`;

const EnrolmentTaskNote = () => {
    const { enrolmentId, courseId, taskId, noteId } = useParams<{
        enrolmentId: string;
        courseId: string;
        taskId: string;
        noteId: string;
    }>();
    const navigate = useNavigate();
    const [standardId] = taskId!.split(".");
    const [user] = useAuth();
    const [profile] = useUserProfileForUser(user?.uid);

    const [, , , loadingStandard] = useStandard(courseId!, Number(standardId));
    const [enrolment, loadingEnrolment] = useEnrolment(enrolmentId!);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>();
    const [photoUrl, setPhotoUrl] = useState<string>();
    const initialNote = enrolment?.tasks[taskId!]?.notes?.[Number(noteId!)];
    const [note, setNote] = useState<Note | undefined>(initialNote);
    const canEdit =
      user?.uid === note?.createdBy.uid;
    const canDelete =
        user?.role === "administrator" || canEdit;
    const getStorageRef = (photo: File) => {
        const ext = photo.name.split(".").pop()?.toLowerCase();
        const ts = format(new Date(), "yyyyMMddHHmmss");
        const storage = getStorage();
        return ref(
          storage,
          `/enrolments/${enrolmentId}/${courseId}/${taskId}-${ts}.${ext}`
        );
    };

    useEffect(() => {
        if (note?.photoRef) {
            const storageRef = ref(getStorage(), note.photoRef);
            getDownloadURL(storageRef).then((url) => {
                setPhotoUrl(url);
            });
        }
        if (!note && initialNote) {
            setNote(initialNote);
        }
    }, [initialNote, note]);

    if (loadingStandard || loadingEnrolment || !enrolment || uploading) {
        return <Loader />;
    }
    const onSubmit = async (values: { note: string; photo?: File }) => {
        if (user && taskId) {
            const note: Note = {
                note: values.note,
                createdBy: {
                    uid: user.uid,
                    name: `${profile?.firstName} ${profile?.lastName || ""}`,
                },
            };
            const file = values.photo;
            if (file) {
                const storageRef = getStorageRef(file);
                setUploading(true);
                try {
                    await uploadBytes(storageRef, file);
                    note.photoRef = storageRef.fullPath;
                } finally {
                    setUploading(false);
                }
            }

            enrolment.tasks[taskId] = {
                ...enrolment.tasks[taskId],
                notes: (enrolment.tasks[taskId]?.notes || []).concat(note),
            };

            updateDoc(
                doc(getFirestore(), Collections.enrolments, enrolment.id),
                {
                    tasks: enrolment.tasks,
                }
            );

            navigate(-1);
        }
    };

    const onEdit = async (values: { note: Note; photo?: File }) => {
        if (user && taskId) {
            setIsEditing(false);
            if (values?.photo) {
                const storageRef = getStorageRef(values?.photo);
                setUploading(true);
                try {
                    await uploadBytes(storageRef, values?.photo);
                    values.note.photoRef = storageRef.fullPath;
                } finally {
                    setUploading(false);
                }
            } else {
                delete values.note.photoRef;
            }
            enrolment.tasks[taskId].notes.splice(Number(noteId), 1, values.note);
            setNote(values.note);
            await updateDoc(
              doc(getFirestore(), Collections.enrolments, enrolment.id),
              {
                  tasks: enrolment.tasks,
              }
            );
        }
    };

    const onDelete = async () => {
        if (taskId) {
            if (note?.photoRef) {
                const storageRef = ref(getStorage(), note.photoRef);
                await deleteObject(storageRef);
            }

            enrolment.tasks[taskId].notes.splice(Number(noteId), 1);
            await updateDoc(
                doc(getFirestore(), Collections.enrolments, enrolment.id),
                {
                    tasks: enrolment.tasks,
                }
            );
        }

        setDeleteDialogOpen(false);
        navigate(-1);
    };

    return (
        <Content>
            <Row>
                <Col xs>
                    <Breadcrumb
                        onClick={() => navigate(-1)}
                        text={standardId}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs>
                    <HeadingD>{!note ? "Add Note" : ""}</HeadingD>
                </Col>
            </Row>

            <Row>
                <Col xs>
                    {(note && !isEditing) && (
                        <>
                            <Label>{`${note.createdBy.name || ""}`}</Label>
                            {note.note}
                            {note?.photoRef && photoUrl && (
                                <Row>
                                    <Col xs>
                                        <Photo src={photoUrl} />
                                    </Col>
                                </Row>
                            )}
                            {canEdit && (
                              <ButtonGroup>
                                  <OutlineButton
                                    disabled={!canEdit}
                                    onClick={() =>
                                      setIsEditing(true)
                                    }
                                  >
                                      Edit note
                                  </OutlineButton>
                              </ButtonGroup>
                            )}
                            {canDelete && (
                                <ButtonGroup>
                                    <RedOutlineButton
                                        disabled={!canDelete}
                                        onClick={() =>
                                            setDeleteDialogOpen(true)
                                        }
                                    >
                                        Delete note
                                    </RedOutlineButton>
                                </ButtonGroup>
                            )}
                        </>
                    )}

                    {(note && isEditing) && (
                      <Form
                        initialValues={{
                            note: note,
                        }}
                        onSubmit={onEdit}
                        schema={yup.object({
                            note: yup.object(),
                            photo: yup.string().nullable(),
                        })}
                      >
                          <label>NOTE</label>
                          <Text name={"note.note"} type={""} />
                          {note.photoRef && photoUrl && (
                            <>
                                <ButtonGroup>
                                    <Row>
                                        <Col xs={6}>
                                            <OutlineButton
                                              onClick={() => {
                                                  setNote({
                                                      ...note,
                                                      photoRef: undefined
                                                  });
                                              }}
                                            >
                                                Remove photo
                                            </OutlineButton>
                                        </Col>
                                    </Row>
                                </ButtonGroup>
                                <Photo src={photoUrl} />
                            </>
                          )}
                          {!note.photoRef && <PhotoSelect name="photo" />}

                          <ButtonGroup>
                              <Row>
                                  <Col xs={6}>
                                      <OutlineButton
                                        onClick={() => setIsEditing(false)}
                                      >
                                          Cancel
                                      </OutlineButton>
                                  </Col>
                                  <Col xs={6}>
                                      <PrimaryButton type={"submit"}>
                                          Update note
                                      </PrimaryButton>
                                  </Col>
                              </Row>
                          </ButtonGroup>
                      </Form>
                    )}
                    {!note && (
                        <Form
                            initialValues={{
                                note: "",
                            }}
                            onSubmit={onSubmit}
                            schema={yup.object({
                                note: yup.string(),
                                photo: yup.string().nullable(),
                            })}
                        >
                            <label>NOTE</label>
                            <Text name={"note"} type={""} />
                            <PhotoSelect name="photo" />

                            <ButtonGroup>
                                <Row>
                                    <Col xs={6}>
                                        <OutlineButton
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </OutlineButton>
                                    </Col>
                                    <Col xs={6}>
                                        <PrimaryButton type={"submit"}>
                                            Save note
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                            </ButtonGroup>
                        </Form>
                    )}
                </Col>
            </Row>
            <Dialog open={deleteDialogOpen}>
                <p>Are you sure you want to delete this note?</p>
                <ButtonGroup>
                    <OutlineButton onClick={() => setDeleteDialogOpen(false)}>
                        Cancel
                    </OutlineButton>
                    <RedButton onClick={onDelete}>Delete</RedButton>
                </ButtonGroup>
            </Dialog>
        </Content>
    );
};

export default EnrolmentTaskNote;
