import styled from 'styled-components';
import { NEUTRAL_200, NEUTRAL_300 } from '../constants/colours';

export const Inset = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background-color: ${NEUTRAL_200};
  border-color: ${NEUTRAL_300};
  border-width: 1px;
  border-style: solid;
  margin: 4px 0;
  cursor: pointer;
`;
