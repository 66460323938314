import { endOfDay, startOfDay } from 'date-fns';

import {
  collection,
  FirestoreDataConverter,
  getFirestore,
  query,
  where,
  Timestamp,
} from 'firebase/firestore';
import {
  useCollectionData,
  useCollectionDataOnce,
} from 'react-firebase-hooks/firestore';
import { Collections } from '../constants/collections';
import { SelfAssessment } from '../types/SelfAssessment';

const assessmentConverter: FirestoreDataConverter<SelfAssessment> = {
  toFirestore(sa: SelfAssessment) {
    return sa;
  },
  fromFirestore(snapshot, options): SelfAssessment {
    const data = snapshot.data(options);
    return data as SelfAssessment;
  },
};

const assessments = () => {
  const db = getFirestore();
  return collection(db, Collections.assessments).withConverter(
    assessmentConverter
  );
};

export function useSelfAssessment(
  pid: string | undefined,
  date: Date
): [SelfAssessment | null, boolean] {
  const startDay = startOfDay(date);
  const endDay = endOfDay(date);

  const q = pid
    ? query(
        assessments(),
        where('pid', '==', pid),
        where('date', '>=', Timestamp.fromDate(startDay)),
        where('date', '<=', Timestamp.fromDate(endDay))
      )
    : null;
  const [data, loading] = useCollectionData<SelfAssessment>(q);

  if (data) {
    return [data[0], loading];
  }
  return [null, loading];
}

export function useSelfAssessmentsRange(
  userId: string,
  startDate: Date,
  endDate: Date
): [SelfAssessment[] | null, boolean] {
  const db = getFirestore();
  const startDay = startOfDay(startDate);
  const endDay = endOfDay(endDate);

  const q = query(
    collection(db, Collections.assessments).withConverter(assessmentConverter),
    where('pid', '==', userId || ''),
    where('date', '>=', Timestamp.fromDate(startDay)),
    where('date', '<=', Timestamp.fromDate(endDay))
  );
  const [data, loading] = useCollectionDataOnce<SelfAssessment>(q);

  if (data) {
    return [data, loading];
  }

  return [null, loading];
}
