import styled from "styled-components";
import config from "./config";
import { PropsWithChildren } from "react";

type RowProps = {
    reverse?: boolean;
    start?: "xs" | "sm" | "md" | "lg";
    center?: "xs" | "sm" | "md" | "lg";
    end?: "xs" | "sm" | "md" | "lg";
    top?: "xs" | "sm" | "md" | "lg";
    middle?: "xs" | "sm" | "md" | "lg";
    bottom?: "xs" | "sm" | "md" | "lg";
    around?: "xs" | "sm" | "md" | "lg";
    between?: "xs" | "sm" | "md" | "lg";
    first?: "xs" | "sm" | "md" | "lg";
    last?: "xs" | "sm" | "md" | "lg";
  };
  
  const Row = styled.div<PropsWithChildren<RowProps>>`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    ${(p) =>
      p.reverse &&
      `
      flex-direction: row-reverse;
    `}
    ${(p) =>
      p.start &&
      config(p).media[p.start]`
      justify-content: flex-start;
    `}
    ${(p) =>
      p.center &&
      config(p).media[p.center]`
      justify-content: center;
    `}
    ${(p) =>
      p.end &&
      config(p).media[p.end]`
      justify-content: flex-end;
    `}
    ${(p) =>
      p.top &&
      config(p).media[p.top]`
      align-items: flex-start;
    `}
    ${(p) =>
      p.middle &&
      config(p).media[p.middle]`
      align-items: center;
    `}
    ${(p) =>
      p.bottom &&
      config(p).media[p.bottom]`
      align-items: flex-end;
    `}
    ${(p) =>
      p.around &&
      config(p).media[p.around]`
      justify-content: space-around;
    `}
    ${(p) =>
      p.between &&
      config(p).media[p.between]`
      justify-content: space-between;
    `}
    ${(p) =>
      p.first &&
      config(p).media[p.first]`
      order: -1;
    `}
    ${(p) =>
      p.last &&
      config(p).media[p.last]`
      order: 1;
    `}
  `;

  Row.displayName = "Row";
  export default Row;