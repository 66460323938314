import styled from "styled-components";
import { LABEL } from "../../constants/typography";
import { NEUTRAL_500 } from "../../constants/colours";

const Label = styled.label`
    ${LABEL};
    line-height: normal;
    font-size: 14px;
    text-transform: uppercase;
    color: ${NEUTRAL_500};
    width: fit-content;
`;

export default Label;