import { Collections } from '../constants/collections';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { Enrolment } from '../types/Course';
import {
  FirestoreDataConverter,
  getFirestore,
  collection,
  doc,
  query,
  where,
} from 'firebase/firestore';

const enrolments = () => {
  const db = getFirestore();
  return collection(db, Collections.enrolments).withConverter(
    enrolmentConverter
  );
};

const enrolmentConverter: FirestoreDataConverter<Enrolment> = {
  toFirestore(e: Enrolment) {
    return e;
  },
  fromFirestore(snapshot, options): Enrolment {
    const data = snapshot.data(options);
    return {
      ...data,
      id: snapshot.id,
      //   ref: snapshot.ref,
    } as Enrolment;
  },
};

export function useEnrolments(studentId?: string | undefined) {
  const q = studentId ? query(enrolments(), where('studentId', '==', studentId)) : query(enrolments());
  return useCollectionData<Enrolment>(q || null);
}

export function useEnrolment(enrolmentId: string | undefined) {
  const db = getFirestore();
  return useDocumentData<Enrolment>(
    enrolmentId
      ? doc(db, Collections.enrolments, enrolmentId).withConverter(
          enrolmentConverter
        )
      : null
  );
}
