import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';
import React, { Ref, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Collections } from '../constants/collections';
import { DAILY_ASSESSMENT_COMPLETED } from '../constants/constants';
import { useAuth } from '../hooks/useAuth';
import { useUserProfileForUser } from '../hooks/useProfiles';
import { SelfAssessment } from '../types/SelfAssessment';
import { OutlineButton, PrimaryButton } from './atoms/Button';
import { Dialog } from './Dialog';
import { Form, Text } from './Forms';
import SelfAssessmentFields, {
  SelfAssessmentSchema,
  SelfAssessmentValues,
} from './SelfAssessmentFields';

const ButtonGroup = styled.div`
  margin-top: 32px;
`;

const MarginButton = styled(PrimaryButton)`
  margin-bottom: 8px;
`;

interface DialogProps {
  onAssessmentCompleted?: () => void;
}

export interface DialogRef {
  show: () => void;
  hide: () => void;
}

function SelfAssessmentDialog(props: DialogProps, ref: Ref<DialogRef>) {
  const [user] = useAuth();
  const [profile] = useUserProfileForUser(user?.uid);
  const [open, setOpen] = useState<boolean>(false);
  const [firstPage, setFirstPage] = useState<boolean>(true);
  const [values, setValues] = useState<SelfAssessmentValues>({
    tahaTinana: null,
    tahaHinengaro: null,
    tahaWairua: null,
    tahaWhanau: null,
  });
  const db = getFirestore();

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const show = () => {
    setOpen(true);
  };

  const hide = () => {
    setOpen(false);
  };

  const handleNoteSubmit = async ({ note }: Pick<SelfAssessment, 'note'>) => {
    await addDoc(collection(db, Collections.assessments), {
      pid: profile?.id,
      date: serverTimestamp(),
      ...values,
      note: note || '',
    });

    window.localStorage.setItem(
      DAILY_ASSESSMENT_COMPLETED,
      new Date().toISOString()
    );
    hide();
    props.onAssessmentCompleted!();
  };

  const handleMetricSubmit = (values: SelfAssessmentValues) => {
    if (
      values.tahaHinengaro ||
      values.tahaTinana ||
      values.tahaWairua ||
      values.tahaWhanau
    ) {
      setValues(values);
      setFirstPage(false);
    }
  };

  return (
    <>
      <Dialog onClose={() => null} open={open}>
        {firstPage ? (
          <>
            <h4>How are you feeling today?</h4>
            <Form
              initialValues={{
                tahaTinana: null,
                tahaHinengaro: null,
                tahaWairua: null,
                tahaWhanau: null,
              }}
              schema={SelfAssessmentSchema}
              onSubmit={handleMetricSubmit}
            >
              <SelfAssessmentFields />
              <MarginButton type={'submit'}>
                Confirm self assessment
              </MarginButton>
              <OutlineButton onClick={hide}>Not now</OutlineButton>
            </Form>
          </>
        ) : (
          <>
            <h4>Would you like to add a note about your Te Whare Tapa Whā?</h4>
            <Form
              initialValues={{
                note: '',
              }}
              onSubmit={handleNoteSubmit}
              schema={yup.object({ note: yup.string() })}
            >
              <label>ADD NOTE</label>
              <Text name={'note'} type={''} />
              <ButtonGroup>
                <MarginButton type={'submit'}>Save assessment</MarginButton>
                <OutlineButton onClick={() => setFirstPage(true)}>
                  Back
                </OutlineButton>
              </ButtonGroup>
            </Form>
          </>
        )}
      </Dialog>
    </>
  );
}

export default React.forwardRef(SelfAssessmentDialog);
