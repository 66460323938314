import {
    getAuth,
    isSignInWithEmailLink,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    updatePassword,
    updateProfile,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FormikProps } from "formik";
import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "../components/grid";
import * as yup from "yup";
import { OutlineButton, PrimaryButton } from "../components/atoms/Button";
import { ButtonGroup } from "../components/ButtonGroup";
import { Content } from "../components/Content";
import {
    Error,
    FieldGroup,
    Form,
    FormHelpers,
    Input,
    Label,
} from "../components/Forms";
import { HeadingD } from "../components/Heading";
import Routes from "../constants/routes";
import { LoadingContext } from "../context/LoadingContext";
// import { useAuth } from "../hooks/useAuth";
import extractQueryParams from "../utils/extractQueryParams";

interface LoginForm {
    email: string;
    password: string;
}

const LoginSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    password: yup.string().required("Required").min(6, "Password too short"),
});

export const Login = () => {
    const initial: LoginForm = { email: "", password: "" };

    const auth = getAuth();

    const formRef = useRef<FormikProps<any>>(null);
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useContext(LoadingContext);

    const submit = async (
        values: LoginForm,
        actions: FormHelpers<LoginForm>
    ) => {
        try {
            // console.log('signing in...');
            await signInWithEmailAndPassword(
                auth,
                values.email,
                values.password
            );
            navigate("/");
        } catch (e: any) {
            console.log(e);
            actions.setStatus({
                error: {
                    message: e.code,
                },
            });
        }
    };

    const handleEmailLink = async (
        { email, password }: LoginForm,
        actions: FormHelpers<LoginForm>
    ) => {
        try {
            setSubmitting(true);
            // store params before redirect after login
            const role = extractQueryParams("user", window.location.search);
            const firstName = extractQueryParams(
                "firstName",
                window.location.search
            );
            const lastName = extractQueryParams(
                "lastName",
                window.location.search
            );
            const phone = extractQueryParams("phone", window.location.search);
            const addressParam = extractQueryParams("address", window.location.search);
            const address = !!addressParam ? decodeURIComponent(addressParam) : undefined;

            const res = await signInWithEmailLink(
                auth,
                email,
                window.location.href
            );

            // console.log("sign in res", res);

            // need auth to exist before callable
            setTimeout(async () => {
                if (res.user) {
                    // console.log("updating pass");
                    await updatePassword(res.user, password);
                    // console.log("updating prof");
                    await updateProfile(res.user, { displayName: firstName });
                    // console.log("get func authorise ");
                    const authorise = httpsCallable(
                        getFunctions(),
                        "authorise"
                    );
                    // console.log("call authorise");
                    await authorise({
                        uid: res.user.uid,
                        role,
                        email,
                        firstName,
                        lastName,
                        phone,
                        address
                    });

                    // force refresh of user after updating
                    window.location.replace("/");
                }
            }, 1000);
        } catch (e: any) {
            console.log("err", e);
            actions.setStatus({ error: { message: e.message } });
            setSubmitting(false);
        }
    };

    if (isSignInWithEmailLink(auth, window.location.href)) {
        const initial = {
            email: "",
            password: "",
        };

        return (
            <Content>
                <Row center={"xs"}>
                    <Col xs>
                        <HeadingD>Confirm email & create a password</HeadingD>
                    </Col>
                </Row>
                <Row center={"xs"}>
                    <Col xs>
                        <Form
                            initialValues={initial}
                            schema={LoginSchema}
                            onSubmit={handleEmailLink}
                        >
                            <FieldGroup>
                                <Label forName={"email"}>Email Address</Label>
                                <Input name={"email"} type={"email"} />
                                <Error forName={"email"} />
                            </FieldGroup>
                            <FieldGroup>
                                <Label forName={"password"}>Password</Label>
                                <Input name={"password"} type={"password"} />
                                <Error forName={"password"} />
                            </FieldGroup>
                            <Error />
                            <PrimaryButton
                                type={"submit"}
                                disabled={submitting}
                            >
                                Submit
                            </PrimaryButton>
                        </Form>
                    </Col>
                </Row>
            </Content>
        );
    }

    return (
        <Content>
            <Row center="xs">
                <Col>
                    <HeadingD>Log In</HeadingD>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs>
                    <Form
                        initialValues={initial}
                        schema={LoginSchema}
                        onSubmit={submit}
                        innerRef={formRef}
                    >
                        <FieldGroup>
                            <Label forName="email">Email Address</Label>
                            <Input name="email" type="email" />
                            <Error forName="email" />
                        </FieldGroup>
                        <FieldGroup>
                            <Label forName="password">Password</Label>
                            <Input name="password" type="password" />
                        </FieldGroup>
                        <Error />
                        <ButtonGroup>
                            <PrimaryButton type="submit">Log In</PrimaryButton>
                            <OutlineButton
                                onClick={() => navigate(Routes.forgotPassword)}
                            >
                                Forgot password
                            </OutlineButton>
                        </ButtonGroup>
                    </Form>
                </Col>
            </Row>
        </Content>
    );
};
