import {
  ErrorMessage,
  Field,
  FieldArray as FormikFieldArray,
  FieldProps,
  Form as FormikForm,
  Formik,
  FormikHelpers,
  useFormikContext,
} from 'formik';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Col, Row } from './grid';
import styled from 'styled-components';
import { NEUTRAL_500 } from '../constants/colours';
import { LABEL } from '../constants/typography';
import { Icon, IconSource } from './atoms/Icon';
import { OptionElement, SelectElement } from './atoms/Select';
import { TextArea } from './atoms/TextArea';
import { TextInput } from './atoms/TextInput';
import SelfAssessmentBar from './SelfAssessmentBar';

export interface FormHelpers<Values> extends FormikHelpers<Values> {}

export const FieldArray = FormikFieldArray;

interface FormProps<Values> {
  initialValues: Values;
  schema: any;
  onSubmit: (
    values: Values,
    helpers: FormHelpers<Values>
  ) => void | Promise<any>;
  children: ReactNode;
  innerRef?: React.Ref<any>;
  enableReinitialize?: boolean;
}

interface InputProps {
  name: string;
  type: string;
  placeholder?: string;
}

interface PhotoSelectProps {
  name: string;
}

interface SelectProps {
  name: string;
  options: { label: string; value: any }[];
}

interface CheckboxProps {
  name: string;
}

interface SelfAssessmentProps {
  name: string;
  label: string;
  disabled?: boolean;
}

interface LabelProps {
  forName?: string;
  children: ReactNode;
}

interface ErrorProps {
  forName?: string;
}

const FieldGroupElement = styled.div`
  margin-bottom: 1rem;
`;

const CheckboxElement = styled.input`
  box-sizing: border-box;
  padding: 0.4rem;
  border: 1px solid black;
  border-radius: 4px;

  &:focus {
    outline: none;
  }
`;

const PhotoSelectLabel = styled.label`
  font-size: inherit;
  width: 100%;
  line-height: 1em;
  text-decoration: underline;
  > img {
    padding: 12px 6px 0 0;
    vertical-align: bottom;
  }
  > input {
    display: none;
  }
`;

const LabelElement = styled.label<any>`
  ${LABEL};
  color: ${NEUTRAL_500};
  text-transform: uppercase;
  display: block;
  font-size: 14px;
  margin-bottom: 4px;
`;

const ErrorElement = styled.div`
  font-size: 80%;
  color: red;
  margin: 0.4rem 0;
`;

export const FieldGroup: React.FC = ({ children }) => {
  return <FieldGroupElement>{children}</FieldGroupElement>;
};

export const Label = ({ forName, children }: LabelProps) => {
  return <LabelElement htmlFor={forName}>{children}</LabelElement>;
};

export const Input = (props: InputProps) => {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => <TextInput {...props} {...field} />}
    </Field>
  );
};

export const Text = (props: InputProps) => {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => <TextArea {...props} {...field} />}
    </Field>
  );
};

export const Select = (props: SelectProps) => {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => (
        <SelectElement {...field}>
          {props.options.map((option) => (
            <OptionElement key={option.value} value={option.value}>
              {option.label}
            </OptionElement>
          ))}
        </SelectElement>
      )}
    </Field>
  );
};

export const Checkbox = (props: CheckboxProps) => {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => (
        <CheckboxElement type="checkbox" {...field} checked={field.value} />
      )}
    </Field>
  );
};

const ImagePreview = styled.img`
  padding: 8px;
`;

export const PhotoSelect = (props: PhotoSelectProps) => {
  const { setFieldValue } = useFormikContext();
  const [file, setFile] = useState<File>();
  const [imageData, setImageData] = useState<string>();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageData(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setImageData(undefined);
    }
  }, [file]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFieldValue(props.name, file);
      setFile(file);
    }
  };

  const onRemove = () => {
    setFieldValue(props.name, null);
    setFile(undefined);
  };

  return (
    <>
      {imageData && (
        <>
          <Row>
            <Col xs>
              <ImagePreview src={imageData} />
            </Col>
          </Row>
          <Row>
            <Col xs>
              <PhotoSelectLabel onClick={onRemove}>
                <Icon source={IconSource.cancel} />
                Remove photo
              </PhotoSelectLabel>
            </Col>
          </Row>
        </>
      )}
      {!imageData && (
        <PhotoSelectLabel>
          <Icon source={IconSource.camera} />
          Attach photo
          <input type="file" onChange={onChange} accept="image/*" />
        </PhotoSelectLabel>
      )}
    </>
  );
};

export const SelfAssessment = (props: SelfAssessmentProps) => {
  return (
    <Field name={props.name}>
      {({ field, form, meta }: FieldProps) => (
        <SelfAssessmentBar
          label={props.label}
          disabled={props.disabled}
          {...field}
          {...form}
          {...meta}
        />
      )}
    </Field>
  );
};

export const Error = ({ forName }: ErrorProps) => {
  const { status } = useFormikContext();

  if (!!forName) {
    return (
      <ErrorElement>
        <ErrorMessage name={forName} />
      </ErrorElement>
    );
  }

  if (status && status.error) {
    return <ErrorElement>{status.error.message}</ErrorElement>;
  }

  return null;
};

export const Form = (props: FormProps<any>) => {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={props.schema}
      validateOnBlur={false}
      validateOnChange={false}
      innerRef={props.innerRef}
      onSubmit={props.onSubmit}
      enableReinitialize={props.enableReinitialize}
    >
      <FormikForm noValidate={true}>{props.children}</FormikForm>
    </Formik>
  );
};
