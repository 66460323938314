import { addDoc, collection, doc, getFirestore, serverTimestamp, updateDoc, } from "firebase/firestore";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "../../components/grid";
import styled from "styled-components";
import * as yup from "yup";
import Action from "../../components/Action";
import { AssessmentCard } from "../../components/AssessmentCard";
import { AssessmentNote } from "../../components/AssessmentNote";
import { OutlineButton, PrimaryButton } from "../../components/atoms/Button";
import { IconSource } from "../../components/atoms/Icon";
import Breadcrumb from "../../components/Breadcrumb";
import { Content } from "../../components/Content";
import { Dialog } from "../../components/Dialog";
import { Error, FieldGroup, Form, Input, Label, Text, } from "../../components/Forms";
import { HeadingD, HeadingL } from "../../components/Heading";
import LabeledValueRow from "../../components/LabeledValueRow";
import Loader from "../../components/Loader";
import SelfAssessmentFields, {
    SelfAssessmentSchema,
    SelfAssessmentValues,
} from "../../components/SelfAssessmentFields";
import { Collections } from "../../constants/collections";
import { DAILY_ASSESSMENT_COMPLETED } from "../../constants/constants";
import Routes from "../../constants/routes";
import { useAuth } from "../../hooks/useAuth";
import { useUserProfileForUser } from "../../hooks/useProfiles";
import { useSelfAssessment } from "../../hooks/useSelfAssessment";
import { UserRole } from "../../types/User";

const EditSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email(),
    phone: yup.string(),
});

type ProfileValues = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
};

const HeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MarginButton = styled(PrimaryButton)`
  margin-bottom: 8px;
  margin-top: 32px;
`;

const SaveButton = styled(PrimaryButton)`
  margin: 8px 0;
`;

export default function MyProfile() {
    const [user, loading] = useAuth();
    const [profile] = useUserProfileForUser(user?.uid);
    const metricsFormRef = useRef<FormikProps<any>>(null);
    const [todayAssessment, loadingAssessment] = useSelfAssessment(
        profile?.id,
        new Date()
    );
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleMetricSubmit = (values: SelfAssessmentValues) => {
        if (
            values.tahaHinengaro ||
            values.tahaTinana ||
            values.tahaWairua ||
            values.tahaWhanau
        ) {
            setDialogOpen(true);
        }
    };

    const handleNoteSubmit = async ({ note }: { note: string }) => {
        const metrics = { ...metricsFormRef?.current?.values };
        Object.keys(metrics).forEach(
            (key) => metrics[key] === undefined && delete metrics[key]
        );

        try {
            await addDoc(collection(getFirestore(), Collections.assessments), {
                pid: profile!.id,
                date: serverTimestamp(),
                ...metrics,
                note,
            });
            window.localStorage.setItem(
                DAILY_ASSESSMENT_COMPLETED,
                new Date().toISOString()
            );
        } catch (e) {
            console.log("error", e);
        } finally {
            setDialogOpen(false);
        }
    };

    const profileSubmit = async (values: ProfileValues) => {
        try {
            if (profile?.id) {
                await updateDoc(
                    doc(getFirestore(), Collections.userProfiles, profile.id),
                    values
                );

                setEditing(false);
            }
        } catch (e) {
            console.log("err", e);
        }
    };

    const renderAssessmentControls = () => {
        if (loadingAssessment) {
            return <Loader small />;
        }

        if (!todayAssessment) {
            return (
                <PrimaryButton type={"submit"} disabled={false}>
                    Submit
                </PrimaryButton>
            );
        }

        if (todayAssessment.note) {
            return (
                <AssessmentNote>
                    <p>{todayAssessment?.note}</p>
                </AssessmentNote>
            );
        }
    };

    return (
        <Content>
            {user?.role !== UserRole.student && (
                <Row>
                    <Col xs>
                        <Breadcrumb
                            text={"Staff"}
                            onClick={() => navigate(Routes.staff.list)}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col xs>
                    {!loading && (
                        <HeadingL>{`${profile?.firstName || ''} ${profile?.lastName || ''}`}</HeadingL>
                    )}
                </Col>
            </Row>
            <HeaderRow>
                <Col xs>
                    <HeadingD>Daily report</HeadingD>
                </Col>
                <Col>
                    <Action
                        text={"history"}
                        icon={IconSource.next}
                        right
                        onClick={() => {
                            if (user?.role === UserRole.student) {
                                navigate(`/profiles/students/${profile?.id}/history`);
                            } else {
                                navigate(`${Routes.staff.list}/${profile?.id}/history`);
                            }
                        }}
                    />
                </Col>
            </HeaderRow>
            <AssessmentCard>
                <Row>
                    <Col xs>
                        <Form
                            initialValues={{
                                tahaTinana: todayAssessment?.tahaTinana || null,
                                tahaHinengaro: todayAssessment?.tahaHinengaro || null,
                                tahaWairua: todayAssessment?.tahaWairua || null,
                                tahaWhanau: todayAssessment?.tahaWhanau || null,
                            }}
                            enableReinitialize={true}
                            schema={SelfAssessmentSchema}
                            onSubmit={handleMetricSubmit}
                            innerRef={metricsFormRef}
                        >
                            <SelfAssessmentFields disabled={!!todayAssessment} />
                            {renderAssessmentControls()}
                        </Form>
                    </Col>
                </Row>
            </AssessmentCard>
            <Row>
                <Col xs>
                    <HeadingD>Details</HeadingD>
                    {editing ? (
                        <Form
                            initialValues={{
                                firstName: profile?.firstName,
                                lastName: profile?.lastName,
                                email: profile?.email,
                                phone: profile?.phone,
                                address: profile?.address
                            }}
                            schema={EditSchema}
                            onSubmit={profileSubmit}
                            enableReinitialize={true}
                        >
                            <FieldGroup>
                                <Label forName={"firstName"}>first name</Label>
                                <Input name={"firstName"} type={"text"} />
                                <Error forName={"firstName"} />
                            </FieldGroup>
                            <FieldGroup>
                                <Label forName={"lastName"}>last name</Label>
                                <Input name={"lastName"} type={"text"} />
                                <Error forName={"lastName"} />
                            </FieldGroup>
                            <FieldGroup>
                                <Label forName={"email"}>email address</Label>
                                <Input name={"email"} type={"email"} />
                                <Error forName={"email"} />
                            </FieldGroup>
                            <FieldGroup>
                                <Label forName={"phone"}>contact number</Label>
                                <Input name={"phone"} type={"tel"} />
                                <Error forName={"phone"} />
                            </FieldGroup>
                            <FieldGroup>
                                <Label forName={"address"}>address</Label>
                                <Input name={"address"} type={"text"} />
                                <Error forName={"address"} />
                            </FieldGroup>
                            <SaveButton type={"submit"}>Save</SaveButton>
                            <OutlineButton onClick={() => setEditing(false)}>
                                Cancel
                            </OutlineButton>
                        </Form>
                    ) : (
                        <>
                            <LabeledValueRow
                                label={"full name"}
                                value={`${profile?.firstName || ''} ${profile?.lastName || ''}`}
                            />
                            <LabeledValueRow label={"email address"} value={profile?.email} />
                            <LabeledValueRow
                                label={"contact number"}
                                value={profile?.phone}
                            />
                            <LabeledValueRow label={"address"} value={profile?.address || ''} />
                            {user?.role !== UserRole.student && (
                                <LabeledValueRow label={"role"} value={profile?.role} />
                            )}
                            {
                                <OutlineButton onClick={() => setEditing(true)}>
                                    Edit details
                                </OutlineButton>
                            }
                        </>
                    )}
                </Col>
            </Row>

            <Dialog open={dialogOpen}>
                <h4>{`Would you like to add a note about Te Whare Tapa Whā?`}</h4>
                <Form
                    initialValues={{ note: "" }}
                    schema={yup.object({ note: yup.string() })}
                    onSubmit={handleNoteSubmit}
                >
                    <label>ADD NOTE</label>
                    <Text name={"note"} type={""} />
                    <MarginButton type={"submit"}>Save assessment</MarginButton>
                    <OutlineButton onClick={() => setDialogOpen(false)}>
                        Cancel
                    </OutlineButton>
                </Form>
            </Dialog>
        </Content>
    );
}
