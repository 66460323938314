import React from 'react';
import { Content } from '../../../components/Content';
import { useAuth } from '../../../hooks/useAuth';
import { HeadingD } from '../../../components/Heading';
import { Col, Row } from '../../../components/grid';
import { useStaffProfiles } from '../../../hooks/useProfiles';
import Loader from '../../../components/Loader';
import { Divider } from '../../../components/atoms/Divider';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Action from '../../../components/Action';
import { IconSource } from '../../../components/atoms/Icon';
import Routes from '../../../constants/routes';
import { UserRole } from '../../../types/User';

const Name = styled.span`
  padding: 0 8px;
`;

const StaffRow = styled.div`
  cursor: pointer;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

export default function StaffList() {
    const [user] = useAuth();
    const navigate = useNavigate();
    const [staff, loadingStaff] = useStaffProfiles();

    const onProfilePress = (id: string) => () => {
        navigate(`/profiles/staff/${id}`);
    };

    const onInvitePress = () => {
        navigate(Routes.staff.invite);
    };

    const renderStaffList = () => {
        if (loadingStaff) {
            return <Loader/>;
        }

        return staff?.map((profile) => (
            <StaffRow onClick={onProfilePress(profile.id)} key={profile.id}>
                <Name>{`${profile.firstName} ${profile.lastName}`}</Name>
                <Divider/>
            </StaffRow>
        ));
    };

    return (
        <Content>
            <Row>
                <Col xs>
                    <HeadingD>Staff</HeadingD>
                </Col>
                {(user?.role === UserRole.administrator) ? (
                    <Col xs>
                        <ActionWrapper>
                            <Action
                                text={'Invite'}
                                icon={IconSource.new}
                                right
                                onClick={onInvitePress}
                            />
                        </ActionWrapper>
                    </Col>
                ) : null}
            </Row>
            <Row>
                <Col xs>
                    <Divider/>
                    {renderStaffList()}
                </Col>
            </Row>
        </Content>
    );
}
