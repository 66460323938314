import { Col, Row } from "./grid";
import styled from "styled-components";
import { LABEL } from "../constants/typography";
import { NEUTRAL_500 } from "../constants/colours";


type LabeledValueRowProps = {
    label: string;
    value: any;
}

const LabeledRow=styled(Row)`
    margin-bottom: 24px;
`
const LabelForValue = styled.span`
    ${LABEL};
    color: ${NEUTRAL_500};
    text-transform: uppercase;
    display: flex;
`




export default function LabeledValueRow(props: LabeledValueRowProps) {
    return (
        <LabeledRow>
            <Col xs>
                <LabelForValue>{props.label}</LabelForValue>
                <span>{props.value}</span>
            </Col>
        </LabeledRow>
    );
}
