import { Drawer } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { NEUTRAL_100, NEUTRAL_900, RED_100 } from "../constants/colours";
import Routes from "../constants/routes";
import { HEADER_HEIGHT, NO_HIGHLIGHT } from "../constants/styles";
import { HEADING_SMALL } from "../constants/typography";
import AmaLogo from "../resources/ama@2x.png";
import { Divider } from "./atoms/Divider";
import { useAuth } from "../hooks/useAuth";
import { UserRole } from "../types/User";
import { useUserProfileForUser } from "../hooks/useProfiles";
import amalogo from "../resources/ama@2x.png";
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${HEADER_HEIGHT};
  z-index: 1400;
`;

const Header = styled.header`
  background-color: ${NEUTRAL_900};
  color: white;
  height: 50px;
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${RED_100};
`;

const StyledHomeIcon = styled(HomeIcon)`
  cursor: pointer;

  & .MuiIcon-root {
    height: 12px;
    width: 12px;
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  cursor: pointer;
`;

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    padding: 0 16px;
  }

  & .MuiDrawer-paperAnchorTop {
    background-color: ${NEUTRAL_900};
    color: ${NEUTRAL_100};
    top: 70px;
    overflow-x: hidden;
  }
`;

const DrawerItem = styled.span`
  cursor: pointer;
  ${HEADING_SMALL};
  padding: 16px 0;
  ${NO_HIGHLIGHT};
`;

const Ama = styled.div`
  background-image: url(${AmaLogo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 160px;
  width: 50%;
  height: 120px;
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`
const HeaderLogo = styled.img`
  height: 35px;
  margin-left: 20px;
`
const Logos = styled.div`
  display: flex;
  justify-content: center;
`;

export const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useAuth();
  const [profile] = useUserProfileForUser(user?.uid);
  const [open, setOpen] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(
    location.pathname !== '/login' && location.pathname !== '/forgot-password'
  );
  const auth = getAuth();

  useEffect(() => {
    setShowMenu(
      location.pathname !== '/login' && location.pathname !== '/forgot-password'
    );
  }, [location]);

  const toggleDrawer = () => {
    setOpen((state) => !state);
  };

  const onDrawerNav = (path: string) => () => {
    setOpen(false);
    navigate(path);
  };

  const onLogout = () => {
    setOpen(false);
    auth.signOut();
  };

  return (
    <Wrapper>
      <Header>
        <HeaderLeft>
        <StyledHomeIcon
          onClick={() => {
            setOpen(false);
            navigate(Routes.students.overview);
          }}
        />

        <HeaderLogo src={amalogo}/>
        </HeaderLeft>
        {showMenu && <StyledMenuIcon onClick={toggleDrawer} />}
      </Header>
      {showMenu && (
        <StyledDrawer anchor={'top'} open={open} onClose={toggleDrawer}>
          {user?.role !== UserRole.student && (
              <>
                <DrawerItem onClick={onDrawerNav(Routes.students.home)}>
                  Find a student
                </DrawerItem>
                <DrawerItem onClick={onDrawerNav(Routes.courses)}>
                  View courses
                </DrawerItem>
                <DrawerItem onClick={onDrawerNav(Routes.staff.list)}>
                  Staff
                </DrawerItem>
              </>
          )}
          <DrawerItem onClick={onDrawerNav(Routes.myProfile)}>
            My account
          </DrawerItem>
          {user?.role === UserRole.student && profile && (
              <DrawerItem onClick={onDrawerNav(Routes.students.enrolments.replace(":id", profile.id))}>
                My courses
              </DrawerItem>
          )}
          <DrawerItem onClick={onLogout}>Log out</DrawerItem>
          <Divider />
          <Logos>
            <Ama />
          </Logos>
        </StyledDrawer>
      )}
    </Wrapper>
  );
};
