import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import {NEUTRAL_200, NEUTRAL_600, NEUTRAL_900, ORANGE_500, PRIMARY} from '../constants/colours';
import { Icon, IconSource } from './atoms/Icon';

type ChecklistProps = {
  text: string;
  onClick?: () => void;
  selected?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  taskNumber?: number;
  readOnly?: boolean;
};

type CheckboxProps = {
  taskNumber?: number;
  highlighted?: boolean;
} & InputHTMLAttributes<HTMLInputElement> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  box-shadow: none;
`;

const StyledCheckbox = styled.div<{ checked: boolean, highlighted: boolean }>`
  display: flex;
  width: 20px;
  height: 20px;
  background: ${(props) => (props.checked ? PRIMARY : props.highlighted ? ORANGE_500 : NEUTRAL_200)};
  border-radius: 50px;
  transition: all 150ms;
  align-items: center;
  justify-content: center;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 0 16px 0 0;
`;

const TaskLabel = styled.div<{ highlighted: boolean }>`
  text-align: center;
  font-size: 12px;
  line-height: 0;
  color: ${(props) => (props.highlighted ? NEUTRAL_900 : NEUTRAL_600)};
`;

const ChecklistWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 16px;
  cursor: pointer;
`;

function Checkbox(props: CheckboxProps) {
  const { taskNumber, ...otherProps } = props;

  return (
    <CheckboxContainer>
      <HiddenCheckbox
        checked={props.checked}
        type={'checkbox'}
        {...otherProps}
      />
      <StyledCheckbox checked={props.checked || false} highlighted={props.highlighted || false}>
        {props.checked ? (
          <Icon source={IconSource.save} actionIcon />
        ) : (
          taskNumber && <TaskLabel highlighted={props.highlighted || false}>{taskNumber}</TaskLabel>
        )}
      </StyledCheckbox>
    </CheckboxContainer>
  );
}

export default function Checklist(props: ChecklistProps) {
  return (
    <ChecklistWrapper>
      <Checkbox {...props} checked={props.selected} highlighted={props.highlighted} />
      <span>{props.text}</span>
    </ChecklistWrapper>
  );
}
